export const routes = (parentName) => {
  return [
    {
      path: "languages/new",
      name: parentName + "-" + "Languages-New",
      component: () => import("./Upsert.vue"),
    },

    {
      path: "languages",
      name: parentName + "-" + "Languages-List",
      component: () => import("./List.vue"),
      props: (route) => ({
        step: route.query.step,
      }),
    },
  ];
};
