export const routes = (parentName) => {
    return [
          {
            path: "analytics",
            name: parentName + "-" +"analytics",
            component: () => import("./View.vue"),
            props: (route) => ({
              step: route.query.step
            }),
          },
    ];
  };
  