<template>
  <transition name="fade">
    <div
      v-if="show"
      style="
        width: 100%;
        height: 100%;
        z-index: 5001;
        position: fixed;
        background-color: #fcfafc;

        background-position: center;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div style="padding-top: 0px; padding-bottom: 0px; text-align: center">
        <!-- <img
          src="/assets/img/gif.gif"
          class="img-fluid height-loader-mobile"
          style=""
          alt=""
        /> -->
        <img
          src="/assets/img/logo-text.png"
          class="img-fluid aniamtion-logo2"
          alt=""
        />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {
      //     show: true
    };
  },
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
  },

  watch: {
    show: {
      handler() {},
    },
  },

  mounted() {
    this.sleep(1000).then(() => {});
  },
};
</script>

<style>
.aniamtion-logo {
  animation: mymove 2s linear infinite;
}
@keyframes mymove {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.aniamtion-logo2 {
  animation: mymove2 1s ease-in;
}
@keyframes mymove2 {
  0% {
    scale: 0;
    opacity: 0;
  }
  100% {
    scale: 1.5;
    opacity: 1;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.height-loader-mobile {
  height: 30rem;
}

@media all and (max-width: 1023px) {
  .height-loader-mobile {
    height: 15rem;
  }
}
</style>
