import store from "../store";
import axios from "axios";

export const checkAuth = async (url, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
      // Authorization: token,
    },
  };
  try {
    const axiosResponse = await axios.post(url, {}, config);
    const serverResponse = axiosResponse.data;
    if (axiosResponse) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const checkUserData = async (url, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
      // Authorization: token,
    },
  };
  try {
    const axiosResponse = await axios.post(url, {}, config);
    const serverResponse = axiosResponse.data;
    if (axiosResponse) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const authLogout = () => {
  store.commit("execClearManagerToken");
};

export const checkAuthOperator = async (url, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      //'x-token-operator': `Bearer ${token}`,
      "x-token-operator": token,
    },
  };
  try {
    const axiosResponse = await axios.post(url, {}, config);
    const serverResponse = axiosResponse.data;
    if (serverResponse.status === "success") {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const authLogoutOperator = () => {
  store.commit("execClearOperatorToken");
};

///////

export const checkAuthUser = async (url, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-token-user": token,
    },
  };
  try {
    const axiosResponse = await axios.post(url, {}, config);
    const serverResponse = axiosResponse.data;
    if (serverResponse.status === "success") {
      return { status: true, data: serverResponse.data };
    } else {
      return { status: false };
    }
  } catch (e) {
    return { status: false };
  }
};

export const authLogoutUser = () => {
  store.commit("execClearUserToken");
};
