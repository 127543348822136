export const routes = (parentName, pathRoot, groupName) => {
  return [
    {
      name: `${parentName}-Login`,
      path: pathRoot + 'auth/login',
      meta: {
        requiresAuthReverse: true,
        group: groupName,
      },
      component: () => import('./Login.vue'),
    },
    {
      name: `${parentName}-Register`,
      path: pathRoot + 'auth/register',
      meta: {
        requiresAuthReverse: true,
        group: groupName,
      },
      component: () => import('./Register.vue'),
    },
    {
      name: `${parentName}-Register-2`,
      path: pathRoot + 'auth/register',
      meta: {
        requiresAuthReverse: true,
        group: groupName,
      },
      component: () => import('./Register2.vue'),
    },
    {
      name: `${parentName}-Logout`,
      path: pathRoot + 'logout',
      component: () => import('./Logout.vue'),
    },

    {
      name: `${parentName}-ForggetPass`,
      path: pathRoot + 'auth/forggetpass',
      component: () => import('./ForggetPass.vue'),
    },

    {
      name: `${parentName}-CheckGoogleAuth`,
      path: pathRoot + 'auth/checkgoogleauth',
      component: () => import('./CheckGoogleAuth.vue'),
    },
  ];
};
