<template>
  <div>
    <!-- <CurvedBottomNavigation
      foreground-color="#FF5757"
      badge-color="#000000"
      background-color="#FFFFFF"
      icon-color="#0000008A"
      :options="options"
      v-model="selected"
    ></CurvedBottomNavigation> -->
    <div
      class="container-fluid d-lg-none"
      style="
        filter: drop-shadow(0 -0.5vw 1vw rgb(34, 34, 34, 0.4));

        background-image: url('/assets/img/34.png');
        background-position-x: center;
        background-repeat: no-repeat;
        z-index: 5555;
        height: 5rem;
        background-size: cover;
        position: fixed;
        bottom: 0;
      "
    >
      <div class="navbarbottom" style="">
        <ul>
          <li>
            <router-link to="/">
              <div class="row">
                <div
                  class="col-12 d-flex justify-content-center align-items-center"
                >
                  <i class="fa-regular fa-star"></i>
                </div>
                <div class="col-12 d-flex justify-content-center mt-2">
                  <p
                    style="
                      font-size: 0.6rem !important;
                      font-weight: bold !important;
                    "
                    class="text-red-ara"
                  >
                    پیشنهادات آرا
                  </p>
                </div>
              </div></router-link
            >
          </li>
          <li>
            <router-link to="/about-us">
              <div class="row">
                <div
                  class="col-12 d-flex justify-content-center align-items-center"
                >
                  <i class="fa-regular fa-heart"></i>
                </div>
                <div class="col-12 col-12 d-flex justify-content-center mt-2">
                  <p
                    style="font-size: 0.6rem !important; font-weight: bold"
                    class="text-red-ara"
                  >
                    علاقه مندی ها
                  </p>
                </div>
              </div>
            </router-link>
          </li>
          <li style="display: flex; justify-content: center">
            <router-link to="/">
              <div id="circle">
                <i
                  class="fa-solid fa-house-chimney"
                  style="transform: rotate(-45deg); color: white !important"
                ></i></div
            ></router-link>
          </li>
          <li>
            <a data-toggle="modal" data-target="#exampleModal">
              <div class="row">
                <div
                  class="col-12 d-flex justify-content-center align-items-center mt-2"
                >
                  <i class="fa-solid fa-headset"></i>
                </div>
                <div class="col-12 d-flex justify-content-center mt-1">
                  <p
                    style="font-size: 0.6rem !important; font-weight: bold"
                    class="text-red-ara"
                  >
                    پشتیبانی
                  </p>
                </div>
              </div>
            </a>
          </li>
          <li>
            <router-link to="/manager/auth/login">
              <div class="row">
                <div
                  class="col-12 d-flex justify-content-center align-items-center mt-2"
                >
                  <i class="fa-solid fa-door-open"></i>
                </div>
                <div class="col-12 mt-2">
                  <p
                    style="font-size: 0.6rem !important; font-weight: bold"
                    class="text-red-ara"
                  >
                    ورود
                  </p>
                </div>
              </div></router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- <div
      class="col-12 w-100 d-lg-none py-1"
      style="
        background-color: white;

        position: fixed;
        bottom: 0;
        z-index: 6666;
      "
    >
      <div class="row">
        <div class="col">
          <router-link to="/">
            <div class="row">
              <div
                class="col-12 d-flex justify-content-center align-items-center"
              >
                <i class="bi bi-house"></i>
              </div>
              <div class="col-12">
                <router-link
                  style="font-size: 0.7rem !important; font-weight: bold"
                  to="/"
                  class="text-red-ara"
                >
                  خانه
                </router-link>
              </div>
            </div></router-link
          >
        </div>
        <div class="col">
          <router-link to="/about-us">
            <div class="row">
              <div
                class="col-12 d-flex justify-content-center align-items-center"
              >
                <i class="bi bi-info"></i>
              </div>
              <div class="col-12">
                <router-link
                  style="font-size: 0.7rem !important; font-weight: bold"
                  to="/about-us"
                  class="text-red-ara"
                >
                  درباره ما
                </router-link>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col">
          <router-link to="/contact-us">
            <div class="row">
              <div
                class="col-12 d-flex justify-content-center align-items-center"
              >
                <i class="bi bi-phone"></i>
              </div>
              <div class="col-12">
                <router-link
                  style="font-size: 0.7rem !important; font-weight: bold"
                  to="/contact-us"
                  class="text-red-ara"
                >
                  تماس با ما
                </router-link>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col">
          <a data-toggle="modal" data-target="#exampleModal">
            <div class="row">
              <div
                class="col-12 d-flex justify-content-center align-items-center"
              >
                <i class="bi bi-mortarboard"></i>
              </div>
              <div class="col-12">
                <a
                  style="font-size: 0.7rem !important; font-weight: bold"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  class="text-red-ara"
                >
                  پشتیبانی
                </a>
              </div>
            </div></a
          >
        </div>
        <div class="col">
          <router-link to="/">
            <div class="row">
              <div
                class="col-12 d-flex justify-content-center align-items-center"
              >
                <i class="bi bi-door-open"></i>
              </div>
              <div class="col-12">
                <router-link
                  style="font-size: 0.7rem !important; font-weight: bold"
                  to="/login-road"
                  class="text-red-ara"
                >
                  ورود
                </router-link>
              </div>
            </div></router-link
          >
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { CurvedBottomNavigation } from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";
export default {
  name: "BottomBar",
  components: { CurvedBottomNavigation },
  data() {
    return {
      selected: 1,
      options: [
        {
          id: 1,
          icon: "fas fa-home",
          title: "Home",
        },
        { id: 2, icon: "fas fa-wallet", title: "Wallet" },
        {
          id: 3,
          icon: "fas fa-plus",
          title: "Setting",
        },
        { id: 4, icon: "fas fa-bell", title: "Notification", badge: 15 },
        { id: 4, icon: "fas fa-bell", title: "Notification", badge: 15 },
      ],
      showMenuMobile: false,
      navbars: [
        { title: "صفحه اصلی", url: "/" },
        { title: "بلاگ", url: "/blog" },
        { title: "درباره ما", url: "/about" },
        { title: "تماس با ما", url: "/contact-us" },
      ],
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
.navbarbottom {
  /* background-color: #d93444; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: 100%;

  bottom: 0;
}
.navbarbottom ul {
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  margin-top: 0rem !important;
  padding: 0;
}

.navbarbottom ul li {
  width: 80px;
  height: 80px;
  color: #333;
  font-size: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
#circle {
  animation: call 3s infinite;
  width: 65px;
  height: 65px;
  background-color: #d93444;
  border-radius: 15px;
  /* top: -35px;
  left: 116px; */
  /* border: 3px solid white; */
  /* margin-left: 40px; */
  margin-top: -5rem;
  filter: drop-shadow(0 -0.5vw 1.3vw rgb(34, 34, 34, 0.4));
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 1023px) {
  .d-mobile-none {
    display: none !important;
  }
}

@media all and (min-width: 992px) {
  .mr-lg-2rem {
    margin-right: 2rem !important;
  }
  .padding-lg-4rem {
    padding: 0 12rem;
  }
}

@media (min-width: 1200px) {
  html[dir="rtl"] .navbar-vertical-aside-show-xl .navbar:not(.navbar-vertical) {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
}
nav {
  max-height: 5rem !important;

  background-color: white !important;
  box-shadow: 1px 1px 10px -10px black;
  border-radius: 0px 0px 20px 20px;
}

.bg-red-ara {
  background-color: #d93444 !important;
}

.text-red-ara {
  color: #d93444 !important;
}

.btn-user {
  border: 1px solid #d93444;
  color: #d93444;
}

i {
  color: black !important;
}

.btn-job {
  background-color: #d93444 !important;
  color: white;
}

.nav-link:hover {
  color: #d93444 !important;
}

.nav-link.router-link-exact-active {
  color: #d93444;
  font-weight: bold;
}

@keyframes call {
  0% {
    transform: scale(0.93) rotate(45deg);
  }
  50% {
    transform: scale(1) rotate(45deg);
  }
  100% {
    transform: scale(0.93) rotate(45deg);
  }
}
</style>
