export const routes = (parentName) => {
  return [
    {
      path: "jobs",
      component: () => import("./Index.vue"),
      children: [
        {
          path: "",
          name: `${parentName}-Jobs`,
          component: () => import("./List.vue"),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: 'new',
          name: `${parentName}-Jobs-New`,
          component: () => import('./Upsert.vue'),
        },
        {
          path: ":id",
          name: parentName + "-" + "Jobs-View",
          component: () => import("./View.vue"),
        },
        {
          path: ":id/edit",
          name: parentName + "-" + "Jobs-Edit",
          component: () => import("./Upsert.vue"),
        },
      ]
    },

  ];
};
