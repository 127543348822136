export const routes = (parentName) => {
  return [
    {
      path: 'history',
      name: `${parentName}-History-Index`,
      component: () => import('./Index.vue'),
      redirect: {
        name: `${parentName}-History-Apicall`,
      },
      children: [
        {
          path: 'apicall',
          component: () => import('./apicall/Index.vue'),
          children: [
            {
              path: '',
              name: `${parentName}-History-Apicall`,
              component: () => import('./apicall/List.vue'),
              query: {
                page: null,
              },
            },
          ],
        },

        {
          path: 'login',
          component: () => import('./login/Index.vue'),
          children: [
            {
              path: '',
              name: `${parentName}-History-Login`,
              component: () => import('./login/List.vue'),
            },
          ],
        },
      ],
    },
  ];
};
