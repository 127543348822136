export const routes = (parentName) => {
  return [
    {
      path: 'zones',
      component: () => import('./Index.vue'),
      children: [
        {
          path: '',
          name: `${parentName}-Zones`,
          component: () => import('./List.vue'),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: 'new',
          name: `${parentName}-Zones-New`,
          component: () => import('./Upsert.vue'),
        },
        {
          path: ':id',
          name: parentName + '-' + 'Zones-View',
          component: () => import('./View.vue'),
        },
        {
          path: ':id/edit',
          name: parentName + '-' + 'Zones-Edit',
          component: () => import('./Upsert.vue'),
        },
        {
          path: ':id/add-camera',
          name: `${parentName}-Zones-Add-Camera`,
          component: () => import('./AddCamera.vue'),
        },
        {
          path: ':id/cameras',
          name: `${parentName}-Zones-Cameras`,
          component: () => import('./Cameras.vue'),
        },
        {
          path: ':id/map',
          name: `${parentName}-Zones-Map`,
          component: () => import('./Map.vue'),
        },
      ],
    },
  ];
};
