export const routes = (parentName) => {
    return [

          {
            path: "dossiers/new",
            name: parentName + "-" +"Dossiers-New",
            component: () => import("./Upsert.vue"),
          },
          {
            path: "dossiers",
            name: parentName + "-" +"Dossiers-List",
            component: () => import("./List.vue"),
            props: (route) => ({
              step: route.query.step
            }),
          },    
         
        
    ];
  };
  