<template>
  <div>
    <!-- support -->
    <div
      class="modal fade"
      style="margin-top: 0rem"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">پشتیبانی</h5>
            <button
              type="button"
              class="close ml-0"
              id="closeModal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <p class="mb-0 text-right">
                  برای ارسال درخواست با واحد پشتیبانی از فرم زیر اقدام فرمایید و
                  برای تماس با کارشناسان از کلید تماس استفاده فرمایین .
                </p>
              </div>
              <div class="col-12 mt-4" style="height: 20rem; overflow: scroll">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group d-flex flex-column">
                      <label class="text-right">نام کامل</label>
                      <input
                        v-model="dataItemSup.name"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group d-flex flex-column">
                      <label class="text-right">ایمیل</label>
                      <input
                        v-model="dataItemSup.email"
                        type="email"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-group mb-3">
                      <input
                        type="number"
                        class="form-control form-control-lg mobile-input"
                        style=""
                        placeholder="شماره تلفن را وارد کنید"
                        v-model="dataItemSup.mobile"
                        autocomplete="off"
                      />
                      <span style="" class="input-group-text" id="basic-addon2"
                        >98+</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group d-flex flex-column">
                      <label class="text-right">توضیحات</label>
                      <textarea
                        v-model="dataItemSup.body"
                        class="form-control"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="padding-bottom: 10rem">
            <a
              :href="'tel:+98' + dataList?.phone"
              type="button"
              style="background-color: green; font-weight: bold"
              class="btn my-2 w-100 text-white"
            >
              تماس با پشتیبانی
              <i class="fa-solid fa-phone"></i>
            </a>
            <ButtonLoading
              type="button"
              style="font-weight: bold"
              class="btn bg-red-ara w-100 text-white"
              :showLoading="showLoading"
              text="ثبت"
              @click="submitForm()"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- choose city -->
    <div
      class="modal fade"
      id="exampleModal2"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              id="closeModals"
              class="close ml-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <p class="mb-0 text-right">شهر مورد نظر را انتخاب کنید</p>
              </div>

              <!-- <div class="col-12 mt-2">
                <input
                  type="text"
                  class="form-control"
                  id="search-in-data"
                  placeholder="جستجو کنید"
                />
              </div> -->
              <div
                class="col-12 mt-4 data-list-ul"
                style="height: 20rem; overflow: auto"
              >
                <div id="accordion">
                  <div
                    v-for="(item, index) in dataList.province"
                    :key="index + 'provinces'"
                    class="card"
                    style="border-radius: 0px !important"
                  >
                    <div
                      class="card-header p-0 hover-cat"
                      style="border-bottom: none !important"
                      :id="'heading' + index"
                    >
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link"
                          style="color: black; text-decoration: none"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          @click="onCityChange(item.id, item.title)"
                        >
                          <!-- toggleChildren(index); -->
                          {{ item.title }}
                        </button>
                      </h5>
                    </div>

                    <div
                      :id="'collapse' + index"
                      :class="{
                        collapse: true,
                        show: activeIndex === index,
                      }"
                      :aria-labelledby="'heading' + index"
                      data-parent="#accordion"
                    >
                      <div class="card-body px-4 py-1">
                        <div class="row">
                          <div
                            v-for="child in item.childs"
                            :key="child.id"
                            class="col-12"
                          >
                            <p
                              @click="submitCity(child.title)"
                              class="mb-0 mt-2"
                              style="cursor: pointer"
                            >
                              {{ child.title }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav
      class="navbar navbar-expand-lg navbar-light w-100 bg-light px-0"
      style="position: fixed; top: 0"
    >
      <div class="container px-0">
        <div class="row w-100 d-lg-none">
          <div class="col-9 d-flex">
            <router-link class="navbar-brand" to="/"
              ><img
                :src="dataList.logo"
                class="img-fluid mt-1"
                style="height: 4rem"
                alt=""
            /></router-link>
          </div>
          <!-- <div class="col-7">
            <input
              type="text"
              class="form-control w-100 d-lg-none"
              style="border-radius: 10px; background-color: #ebebeb"
              placeholder="دنبال چی میگردی"
            />
          </div> -->
          <div class="col-3 px-0" style="display: flex; align-items: center">
            <span data-toggle="modal" data-target="#exampleModal2" class="">
              <i class="bi bi-pin-map mx-2"></i> {{ choosenCityName }}
            </span>

            <!-- <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button> -->
          </div>
        </div>

        <div
          class="collapse navbar-collapse"
          :class="{ show: showMenuMobile }"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mr-auto">
            <li class="nav-item d-mobile-none">
              <router-link class="nav-link" to="/"
                ><img
                  :src="dataList.logo"
                  class="img-fluid"
                  style="height: 4rem"
                  alt=""
              /></router-link>
            </li>
            <li class="nav-item mx-2">
              <a
                data-toggle="modal"
                data-target="#exampleModal2"
                style="cursor: pointer"
                class="nav-link mx-3"
              >
                <i class="bi bi-pin-map mx-2"></i> {{ choosenCityName }}
              </a>
            </li>
            <li style="width: 20rem" class="mr-lg-4">
              <input
                @keyup="filterPro()"
                v-model="searchField"
                type="text"
                class="form-control w-100"
                style="border-radius: 10px; background-color: #ebebeb"
                placeholder="دنبال چی میگردی"
              />
              <div
                v-if="searchBox"
                class=""
                style="
                  overflow-x: hidden;
                  width: 20rem;
                  height: 10rem;
                  position: absolute;
                  background-color: white;
                  margin-top: 1rem;
                  border-radius: 10px;
                  overflow-y: scroll;
                "
              >
                <button
                  type="button"
                  class="close ml-0"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="this.searchBox = false"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div
                  v-if="loadingProducts"
                  class="spinner-border mt-3"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="row">
                  <div v-if="dataListProducts.length < 1" class="col-12">
                    <h6 class="text-center">اطلاعاتی وجود ندارد</h6>
                  </div>
                  <div
                    v-for="(item, index) in dataListProducts"
                    :key="index"
                    class="col-12"
                  >
                    <router-link
                      style="text-decoration: none !important"
                      :to="{
                        name: 'PublicSingelProduct',
                        params: {
                          name: item.name,
                        },
                      }"
                    >
                      <div class="row">
                        <div class="col-lg-3">
                          <img
                            :src="item.image"
                            class="img-fluid"
                            style="height: 3rem"
                            alt=""
                          />
                        </div>
                        <div
                          class="col-lg-9"
                          style="
                            display: flex;

                            align-items: center;
                          "
                        >
                          <h6 class="text-right mb-0">
                            {{ item.name }}
                          </h6>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
            <ul class="navbar-nav">
              <li class="nav-item mx-2">
                <router-link to="/" class="nav-link header-item-active"
                  >صفحه اصلی</router-link
                >
              </li>
              <li></li>
              <li class="nav-item mx-2">
                <router-link class="nav-link header-item-active" to="/about-us"
                  >درباره ما</router-link
                >
              </li>
              <!-- <li class="nav-item mx-2">
                <router-link
                  class="nav-link header-item-active"
                  to="/contact-us"
                  >تماس با ما</router-link
                >
              </li> -->

              <li class="nav-item mx-2">
                <router-link class="nav-link header-item-active" to="/rules"
                  >قوانین</router-link
                >
              </li>
              <li class="nav-item mx-2">
                <a
                  class="nav-link header-item-active"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  style="cursor: pointer"
                  >پشتیبانی</a
                >
              </li>
            </ul>
          </ul>

          <div class="form-inline d-flex justify-content-center">
            <router-link
              :to="{ name: 'PanelManager-Login' }"
              class="btn btn-user my-2 my-sm-0 mt-lg-4 mx-2 mt-4"
              style="font-weight: bold"
              type="submit"
            >
              ورود کاربر
            </router-link>
            <!-- <a
              href="/manager/auth/login"
              class="btn btn-job my-2 my-sm-0 mx-2 mt-4"
              type="submit"
              target="_blank"
            >
              ورود مشاغل
            </a> -->
          </div>
        </div>
      </div>
    </nav>
  </div>
  <!-- das -->
</template>

<script>
import axios from "axios";
import { toastSuccess } from "@/utils/toast";
import ButtonLoading from "@/components/custom/ButtonLoading.vue";
export default {
  name: "Navbar",
  data() {
    return {
      showMenuMobile: false,
      activeIndex: null,
      choosenCity: null,
      choosenCityName: "تهران",
      dataList: [],
      dataItemSup: { name: "", email: "", mobile: "", body: "" },
      showLoading: false,
      searchBox: false,
      loadingProducts: false,
      dataListProducts: [],
      searchField: "",
      navbars: [
        { title: "صفحه اصلی", url: "/" },
        { title: "بلاگ", url: "/blog" },
        { title: "درباره ما", url: "/about" },
        { title: "تماس با ما", url: "/contact-us" },
      ],
    };
  },
  components: {
    ButtonLoading,
  },
  methods: {
    filterPro() {
      const data = JSON.stringify({
        start: 0,
        step: 100,
        search: this.searchField,
      });
      this.searchBox = true;
      this.loadingProducts = true;
      const url = "/products";
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .post(url, data, config)
        .then((response) => {
          this.loadingProducts = false;
          const serverRespone = response.data;
          this.dataListProducts = serverRespone.data;
        })
        .catch((error) => {
          this.loadingProducts = false;
        });
    },
    onCityChange(id, name) {
      this.$emit("city-selected", (this.choosenCity = id));
      this.choosenCityName = name;
      document.getElementById("closeModal").click();
      document.getElementById("closeModals").click();
    },
    submitCity(city) {
      this.choosenCity = city;
      document.getElementById("closeModal").click();
      document.getElementById("closeModals").click();
      console.log(city);
    },
    toggleChildren(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    getData() {
      this.loading = true;
      const url = "/data";
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .post(url, {}, config)
        .then((response) => {
          this.loading = false;
          const serverRespone = response.data;
          this.dataList = serverRespone.data;
        })
        .catch((error) => {});
    },
    submitForm() {
      if (
        this.dataItemSup.name !== "" ||
        this.dataItemSup.body !== "" ||
        this.dataItemSup.email !== "" ||
        this.dataItemSup.mobile !== ""
      ) {
        this.showLoading = true;
        let url = "/supports/add";

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const data = JSON.stringify({
          name: this.dataItemSup.name,
          mobile: "0" + this.dataItemSup.mobile,
          body: this.dataItemSup.body,
          email: this.dataItemSup.email,
        });
        axios
          .post(url, data, config)
          .then((response) => {
            const serverResponse = response.data;
            this.showLoading = false;
            toastSuccess(serverResponse.message);
          })
          .catch((error) => {
            this.showLoading = false;
          });
      }
    },
  },
  mounted() {
    this.getData();
    $(document).ready(function () {
      $("#search-in-data").on("keyup", function () {
        let value = $(this).val().toLowerCase();
        $("#data-list-ul button ").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });
  },
};
</script>

<style scoped>
@media all and (max-width: 1023px) {
  .d-mobile-none {
    display: none !important;
  }
}

@media all and (min-width: 992px) {
  .mr-lg-2rem {
    margin-right: 2rem !important;
  }
  .padding-lg-4rem {
    padding: 0 12rem;
  }
}

@media (min-width: 1200px) {
  html[dir="rtl"] .navbar-vertical-aside-show-xl .navbar:not(.navbar-vertical) {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
}
nav {
  max-height: 5rem !important;

  background-color: white !important;
  box-shadow: 1px 1px 10px -10px black;
  border-radius: 0px 0px 20px 20px;
}

.bg-red-ara {
  background-color: #d93444 !important;
}

.text-red-ara {
  color: #d93444 !important;
}

.btn-user {
  border: 1px solid #d93444;
  color: #d93444;
}

.btn-job {
  background-color: #d93444 !important;
  color: white;
}

.nav-link:hover {
  color: #d93444 !important;
}

.nav-link.router-link-exact-active {
  color: #d93444;
  font-weight: bold;
}
</style>
