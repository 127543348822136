export const routes = (parentName) => {
    return [
     
        {
            path: "pages/:id",
            name: parentName + "-" +"Pages-Show",
            component: () => import("./View.vue"),
          },
    
          {
            path: "pages/new",
            name: parentName + "-" +"Pages-New",
            component: () => import("./Upsert.vue"),
          },
    
          {
            path: "pages",
            name: parentName + "-" +"Pages-List",
            component: () => import("./List.vue"),
            props: (route) => ({
              step: route.query.step
            }),
          },
        
    ];
  };
  