<template>
  <div
    class="bw has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset"
  >
    <div
      :class="{ 'display-none-modal': displayNoneMobile }"
      id="myModal"
      class="modal"
    >
      <div class="modal-content">
        <div class="row">
          <!-- <div class="col-12">
            <span @click="displayNoneMobile = true" class="close">&times;</span>
          </div> -->

          <div class="col-12" style="height: 30rem; overflow: scroll">
            <div
              v-if="boxRoForm"
              class="row"
              style="
                position: absolute;
                overflow: hidden;
                width: 100%;
                background-color: white;
                z-index: 44444;
              "
            >
              <div
                class="col-12 d-flex justify-content-center align-items-center"
                style="height: 30rem"
              >
                <img
                  src="/assets/img/logo-text.png"
                  class="img-fluid aniamtion-logo2"
                  alt=""
                />
              </div>
            </div>
            <div class="row my-3">
              <div class="col-12 d-flex justify-content-center">
                <div class="form-inline">
                  <div class="form-check">
                    <input
                      v-model="chooseAll"
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios1"
                      value="NORMAL"
                    />
                    <label class="form-check-label mx-3" for="exampleRadios1">
                      کاربر عادی
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="chooseAll"
                      class="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios2"
                      value="JOB"
                    />
                    <label class="form-check-label mx-3" for="exampleRadios2">
                      مشاغل
                    </label>
                  </div>
                </div>
              </div>
              <div
                v-if="chooseAll === 'NORMAL'"
                class="col-12 d-flex justify-content-center flex-column"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="">نام و نام خانوادگی </label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="name"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="">شماره تماس </label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="mobile"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="">ایمیل </label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="email"
                          />
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-group">
                          <label for="">استان </label>
                          <select class="form-control" v-model="province">
                            <option
                              v-for="(item, index) in dataList.province"
                              :key="index + 'provinces'"
                              :value="item.id"
                            >
                              {{ item.title }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-group">
                          <label for="">آدرس </label>
                          <textarea
                            name=""
                            class="form-control"
                            v-model="address"
                            id=""
                            cols="30"
                            rows="10"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <ButtonLoading
                      class="btn-cus mt-4"
                      :showLoading="showLoading"
                      text="ثبت"
                      @click="submitNormalUser()"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="chooseAll === 'JOB'" class="row">
              <div v-show="workCategorySection" class="col-12">
                <div class="row d-flex justify-content-center">
                  <div class="col-lg-6 col-12">
                    <div class="form-group">
                      <label for="">حوزه مورد نظر خود را انتخاب کنید</label>
                      <select
                        v-model="workDataItem.numberOfWork"
                        name=""
                        class="form-control py-0"
                        id=""
                      >
                        <option :value="1">معدن داران و پیمانکاران</option>
                        <option :value="2">
                          صنعت گران ساختمان و تولید کنندگان
                        </option>
                        <option :value="3">
                          ساختمان سازی و املاک و مستغلات
                        </option>
                        <option :value="4">سرمایه گذاری و طرح توجیحی</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <!-- معدن -->
                    <div
                      v-show="workDataItem.numberOfWork === 1"
                      class="col-12"
                    >
                      <div class="row d-flex justify-content-center">
                        <div class="col-lg-6 col-12">
                          <div class="form-group">
                            <label for=""
                              >حوزه مورد نظر خود را انتخاب کنید</label
                            >
                            <select
                              v-model="workDataItem.minWorkSelectNumber"
                              name=""
                              class="form-control py-0"
                              id=""
                            >
                              <option :value="1">
                                خرید ماشین آلات و ابزار مورد نیاز
                              </option>
                              <option :value="2">
                                استفاده از خدمات و مشاوره آرا
                              </option>
                              <option :value="3">
                                درخواست حضور به عنوان فروشنده مواد معدنی
                              </option>
                              <option :value="4">
                                تمامی موارد (دارای شرایط ویژه همکاری)
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div
                            v-if="workDataItem.minWorkSelectNumber === 1"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">نام شرکت</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="dataItemEnMineOne.companyName"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">سمت فرد متقاضی</label>
                                    <select
                                      class="form-control"
                                      v-model="dataItemEnMineOne.position"
                                      name=""
                                      id=""
                                    >
                                      <option value="مدیرعامل">مدیرعامل</option>
                                      <option value="صاحب پروانه">
                                        صاحب پروانه
                                      </option>
                                      <option value="مدیر فروش">
                                        مدیر فروش
                                      </option>
                                      <option value="پیمانکار">پیمانکار</option>
                                      <option value="مدیر بازرگانی">
                                        مدیر بازرگانی
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""> آدرس واحد معدنی</label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="dataItemEnMineOne.address"
                                  ></textarea>
                                </div>
                                <div class="form-group">
                                  <ButtonLoading
                                    class="btn-cus mt-4"
                                    :showLoading="showLoading"
                                    text="ثبت"
                                    @click="submitForm()"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="workDataItem.minWorkSelectNumber === 2"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-inline">
                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="dataItemEnMineTwo.servicesArray"
                                      value="برندینگ و تبلیغات"
                                    />
                                    <label class="form-check-label">
                                      برندینگ و تبلیغات
                                    </label>

                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="dataItemEnMineTwo.servicesArray"
                                      value="بازرگانی خارجی"
                                    />
                                    <label class="form-check-label">
                                      بازرگانی خارجی
                                    </label>

                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="dataItemEnMineTwo.servicesArray"
                                      value="جذب سرمایه برای توسعه یا راه اندازی"
                                    />
                                    <label class="form-check-label">
                                      جذب سرمایه برای توسعه یا راه اندازی
                                    </label>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <ButtonLoading
                                    class="btn-cus mt-4"
                                    :showLoading="showLoading"
                                    text="ثبت"
                                    @click="submitForm()"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="workDataItem.minWorkSelectNumber === 3"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">نام شرکت</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="dataItemEnMineThree.companyName"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">سمت فرد متقاضی</label>
                                    <select
                                      class="form-control"
                                      v-model="dataItemEnMineThree.position"
                                      name=""
                                      id=""
                                    >
                                      <option value="مدیرعامل">مدیرعامل</option>
                                      <option value="صاحب پروانه">
                                        صاحب پروانه
                                      </option>
                                      <option value="مدیر فروش">
                                        مدیر فروش
                                      </option>
                                      <option value="پیمانکار">پیمانکار</option>
                                      <option value="مدیر بازرگانی">
                                        مدیر بازرگانی
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""> آدرس واحد معدنی</label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="dataItemEnMineThree.address"
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <label for=""> آدرس دفتر مرکزی</label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="dataItemEnMineThree.officeAddress"
                                  ></textarea>
                                  {{ dataItemEnMineThree.officeAddress }}
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">لیست تولیدات و کیفیت</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="dataItemEnMineThree.qualityList"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">میزان تولید در سال</label>
                                    <input
                                      type="number"
                                      class="form-control"
                                      v-model="dataItemEnMineThree.countTolid"
                                    />
                                  </div>
                                </div>
                                <ButtonLoading
                                  class="btn-cus mt-4"
                                  :showLoading="showLoading"
                                  text="ثبت"
                                  @click="submitForm()"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="workDataItem.minWorkSelectNumber === 4"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">نام شرکت</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="dataItemEnMineFour.companyName"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">عناصر اصلی</label>
                                    <p class="mb-0 text-muted">
                                      : طبق زیر پر شود
                                    </p>

                                    <p class="mb-0 text-muted">
                                      مشخصات عناصر اصلی حداکثر خلوص مورد انتطار
                                      حداقل خلوص مورد انتطار
                                    </p>
                                    <textarea
                                      v-model="dataItemEnMineFour.onsorAsli"
                                      name=""
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    >
                                    برای مثال : آهک  ۱۸درصد  ۱۰درصد - سیلیس ۱۰درصد ۵درصد
                                    </textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">عناصر فرعی</label>
                                    <p class="mb-0 text-muted">
                                      : طبق زیر پر شود
                                    </p>

                                    <p class="mb-0 text-muted">
                                      مشخصات عناصر فرعی حداکثر خلوص مورد انتطار
                                      حداقل خلوص مورد انتطار
                                    </p>
                                    <textarea
                                      v-model="dataItemEnMineFour.onsorFarii"
                                      name=""
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    >
                                    برای مثال : آهک  ۱۸درصد  ۱۰درصد - سیلیس ۱۰درصد ۵درصد
                                    </textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">سمت فرد متقاضی</label>
                                    <select
                                      class="form-control"
                                      v-model="dataItemEnMineFour.position"
                                      name=""
                                      id=""
                                    >
                                      <option value="مدیرعامل">مدیرعامل</option>
                                      <option value="صاحب پروانه">
                                        صاحب پروانه
                                      </option>
                                      <option value="مدیر فروش">
                                        مدیر فروش
                                      </option>
                                      <option value="پیمانکار">پیمانکار</option>
                                      <option value="مدیر بازرگانی">
                                        مدیر بازرگانی
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""> آدرس واحد معدنی</label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="dataItemEnMineFour.address"
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <label for=""> آدرس دفتر مرکزی</label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="dataItemEnMineFour.officeAddress"
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">لیست تولیدات و کیفیت</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="dataItemEnMineFour.qualityList"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">میزان تولید در سال</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="dataItemEnMineFour.countTolid"
                                    />
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""> پروانه اکتشاف</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="dataItemEnMineFour.parvanehEk"
                                      id=""
                                    >
                                      <option value="دارد">دارد</option>
                                      <option value="ندارد">ندارد</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">پروانه بهره برداری</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="dataItemEnMineFour.parvanehBah"
                                      id=""
                                    >
                                      <option value="دارد">دارد</option>
                                      <option value="ندارد">ندارد</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""
                                      >معدن در حال بهره برداری</label
                                    >
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="dataItemEnMineFour.mineBahreh"
                                      id=""
                                    >
                                      <option value="میباشد">میباشد</option>
                                      <option value="نمیباشد">نمیباشد</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">معدن سیستم خردایش</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="
                                        dataItemEnMineFour.mineSystemKhar
                                      "
                                      id=""
                                    >
                                      <option value="دارد">دارد</option>
                                      <option value="ندارد">ندارد</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">معدن سیستم استحصال</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="
                                        dataItemEnMineFour.mineSystemEstehsal
                                      "
                                      id=""
                                    >
                                      <option value="دارد">دارد</option>
                                      <option value="ندارد">ندارد</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">بازار هدف</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="dataItemEnMineFour.goalBazar"
                                      id=""
                                    >
                                      <option value="ساختمان ها">
                                        ساختمان ها
                                      </option>
                                      <option value="کارخانه ها">
                                        کارخانه ها
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">شرایط فروش</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="dataItemEnMineFour.sharayetFrosh"
                                      id=""
                                    >
                                      <option value="نقدی">نقدی</option>
                                      <option value="ضمانت نامه">
                                        ضمانت نامه
                                      </option>
                                      <option value="تهاتر (ملک ...)">
                                        تهاتر (ملک ...)
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for="">توضیحات شرایط فروش</label>
                                  <div class="form-group">
                                    <textarea
                                      name=""
                                      v-model="dataItemEnMineFour.descFro"
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""
                                    >پیشنهاد درصد تخفیف یا دستمزد کارگزاری فروش
                                    مواد معدنی</label
                                  >
                                  <div class="form-group">
                                    <textarea
                                      name=""
                                      v-model="
                                        dataItemEnMineFour.suggestDisCount
                                      "
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">سابقه صادرات</label>

                                    <textarea
                                      name=""
                                      v-model="
                                        dataItemEnMineFour.saderatHistory
                                      "
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                      placeholder="مثال :  بله یا خیر در صورت بله بودن نام کشور هارو بنویسید در غیر این صورت بنویسید که تمایل صادرات دارید"
                                    ></textarea>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">درخواست مشاوره</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="dataItemEnMineFour.moshavere"
                                      id=""
                                    >
                                      <option value="فروش(واگذاری)معدن">
                                        فروش(واگذاری)معدن
                                      </option>
                                      <option
                                        value="راه اندازی معدن (پیمانکار یا جذب سرمایه)"
                                      >
                                        راه اندازی معدن (پیمانکار یا جذب سرمایه)
                                      </option>
                                      <option value="مشارکت معدن">
                                        مشارکت معدن
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">توضیحات مشاوره</label>
                                    <textarea
                                      name=""
                                      v-model="dataItemEnMineFour.descMoshavere"
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">هزینه ی حمل و نقل</label>
                                    <textarea
                                      name=""
                                      v-model="dataItemEnMineFour.priceTrans"
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                      placeholder="برای مثال : نمیپذیرم یا میپذیرم در شهرهای تهران ، اصفهان و ..."
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""
                                      >در زمینه برندینگ و تبلیغات کدام یکی از
                                      روش های زیر را استفاده کرده اید</label
                                    >
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="dataItemEnMineFour.branding"
                                      id=""
                                    >
                                      <option value="فضای مجازی">
                                        فضای مجازی
                                      </option>
                                      <option value="نمایندگی ها">
                                        نمایندگی ها
                                      </option>
                                      <option value="سایت">سایت</option>
                                      <option value="عکاسی و فیلم برداری">
                                        عکاسی و فیلم برداری
                                      </option>
                                      <option value="بیلبورد">بیلبورد</option>
                                      <option
                                        value="انجام نشده درخواست برندینگ دارم"
                                      >
                                        انجام نشده درخواست برندینگ دارم
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <ButtonLoading
                                  class="btn-cus mt-4"
                                  :showLoading="showLoading"
                                  text="ثبت"
                                  @click="submitForm()"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- صنعتگران -->
                    <div
                      v-show="workDataItem.numberOfWork === 2"
                      class="col-12"
                    >
                      <div class="row d-flex justify-content-center">
                        <div class="col-lg-6 col-12">
                          <div class="form-group">
                            <label for=""
                              >حوزه مورد نظر خود را انتخاب کنید</label
                            >
                            <select
                              v-model="workDataItem.industrialWorkSelectNumber"
                              name=""
                              class="form-control py-0"
                              id=""
                            >
                              <option :value="1">
                                درخواست خرید مواد اولیه کارخانه خود از معدن
                              </option>
                              <option :value="2">
                                استفاده از خدمات و مشاوره آرا
                              </option>
                              <option :value="3">
                                درخواست حضور به عنوان فروشنده لوازم صنعتی
                              </option>
                              <option :value="4">
                                تمامی موارد (دارای شرایط ویژه همکاری)
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div
                            v-if="workDataItem.industrialWorkSelectNumber === 1"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""
                                      >نوع مواد اولیه و میزان تولید
                                      سالیانه</label
                                    >
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="dataItemEnIndustrialOne.type"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">سمت فرد متقاضی</label>
                                    <select
                                      class="form-control"
                                      v-model="dataItemEnIndustrialOne.position"
                                      name=""
                                      id=""
                                    >
                                      <option value="مدیرعامل">مدیرعامل</option>
                                      <option value="صاحب پروانه">
                                        صاحب پروانه
                                      </option>
                                      <option value="مدیر فروش">
                                        مدیر فروش
                                      </option>
                                      <option value="پیمانکار">پیمانکار</option>
                                      <option value="مدیر بازرگانی">
                                        مدیر بازرگانی
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""> آدرس کارخانه </label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="dataItemEnIndustrialOne.address"
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <ButtonLoading
                                    class="btn-cus mt-4"
                                    :showLoading="showLoading"
                                    text="ثبت"
                                    @click="submitForm()"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="workDataItem.industrialWorkSelectNumber === 2"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-inline">
                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="
                                        dataItemEnIndustrialTwo.servicesArray
                                      "
                                      value="برندینگ و تبلیغات"
                                    />
                                    <label class="form-check-label">
                                      برندینگ و تبلیغات
                                    </label>

                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="
                                        dataItemEnIndustrialTwo.servicesArray
                                      "
                                      value="بازرگانی خارجی"
                                    />
                                    <label class="form-check-label">
                                      بازرگانی خارجی
                                    </label>

                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="
                                        dataItemEnIndustrialTwo.servicesArray
                                      "
                                      value="جذب سرمایه برای توسعه یا تعمیرات"
                                    />
                                    <label class="form-check-label">
                                      جذب سرمایه برای توسعه یا تعمیرات
                                    </label>

                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="
                                        dataItemEnIndustrialTwo.servicesArray
                                      "
                                      value="فروش کارخانه"
                                    />
                                    <label class="form-check-label">
                                      فروش کارخانه
                                    </label>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <ButtonLoading
                                    class="btn-cus mt-4"
                                    :showLoading="showLoading"
                                    text="ثبت"
                                    @click="submitForm()"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="workDataItem.industrialWorkSelectNumber === 3"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">سمت فرد متقاضی</label>
                                    <select
                                      class="form-control"
                                      v-model="
                                        dataItemEnIndustrialThree.position
                                      "
                                      name=""
                                      id=""
                                    >
                                      <option value="مدیرعامل">مدیرعامل</option>
                                      <option value="صاحب پروانه">
                                        صاحب پروانه
                                      </option>
                                      <option value="مدیر فروش">
                                        مدیر فروش
                                      </option>
                                      <option value="پیمانکار">پیمانکار</option>
                                      <option value="مدیر بازرگانی">
                                        مدیر بازرگانی
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for="">
                                    آدرس کارخانه (واحد تولید)
                                  </label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="dataItemEnIndustrialThree.address"
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <label for=""> آدرس دفتر مرکزی </label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="
                                      dataItemEnIndustrialThree.addressMarkaz
                                    "
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <label for=""> آدرس انبار و نمایندگی </label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="
                                      dataItemEnIndustrialThree.addressAnbar
                                    "
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <label for=""> جدول لیست محصولات </label>
                                  <p class="text-muted mb-0">طبق زیر پرشود :</p>
                                  <p class="text-muted mb-0">محصول کیفیت آن</p>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="
                                      dataItemEnIndustrialThree.productList
                                    "
                                    placeholder="برای مثال : نخ متوسط - پارچه عالی"
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <label for=""> میزان تولید در سال </label>
                                  <input
                                    v-model="
                                      dataItemEnIndustrialThree.tolidcount
                                    "
                                    type="text"
                                    class="form-control"
                                  />
                                </div>
                                <div class="col-12">
                                  <label for=""> حمل و نقل </label>

                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="
                                      dataItemEnIndustrialThree.transPort
                                    "
                                    placeholder="برای مثال : نمیپذیرم یا میپذیرم در شهر های تهران ، اراک و ..."
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <ButtonLoading
                                    class="btn-cus mt-4"
                                    :showLoading="showLoading"
                                    text="ثبت"
                                    @click="submitForm()"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="workDataItem.industrialWorkSelectNumber === 4"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">سمت فرد متقاضی </label>
                                    <select
                                      class="form-control"
                                      v-model="
                                        dataItemEnIndustrialFour.position
                                      "
                                      name=""
                                      id=""
                                    >
                                      <option value="کارخانه">کارخانه</option>
                                      <option value="تولیدی">تولیدی</option>
                                      <option value="وارد کننده">
                                        وارد کننده
                                      </option>
                                      <option value="بنکدار">بنکدار</option>

                                      <option value="مدیرعامل">مدیرعامل</option>

                                      <option value="مدیر فروش">
                                        مدیر فروش
                                      </option>

                                      <option value="مدیر بازرگانی">
                                        مدیر بازرگانی
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">
                                      نام برند یا مجموعه شرکت ها
                                    </label>
                                    <input
                                      class="form-control"
                                      v-model="
                                        dataItemEnIndustrialFour.nameBrand
                                      "
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for="">
                                    آدرس کارخانه (واحد تولید)
                                  </label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="
                                      dataItemEnIndustrialFour.addressTolid
                                    "
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">
                                      شماره تلفن واحد تولید
                                    </label>
                                    <input
                                      class="form-control"
                                      v-model="
                                        dataItemEnIndustrialFour.tolidNumber
                                      "
                                      type="text"
                                    />
                                  </div>
                                </div>

                                <div class="col-12">
                                  <label for=""> آدرس دفتر مرکزی </label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="
                                      dataItemEnIndustrialFour.addressMark
                                    "
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">
                                      شماره تلفن دفتر مرکزی
                                    </label>
                                    <input
                                      class="form-control"
                                      v-model="
                                        dataItemEnIndustrialFour.markazNumber
                                      "
                                      type="text"
                                    />
                                  </div>
                                </div>

                                <div class="col-12">
                                  <label for=""> آدرس نمایندگی ها </label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="
                                      dataItemEnIndustrialFour.addressNama
                                    "
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">
                                      شماره تلفن نمایندگی ها
                                    </label>
                                    <input
                                      class="form-control"
                                      v-model="
                                        dataItemEnIndustrialFour.NamaNumber
                                      "
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for="">
                                    در کدام استان ها فروش بیشتری دارید
                                  </label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="
                                      dataItemEnIndustrialFour.foroshProvince
                                    "
                                  ></textarea>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""> درجه بندی محصولات </label>
                                    <select
                                      class="form-control"
                                      v-model="
                                        dataItemEnIndustrialFour.degreeProduct
                                      "
                                      name=""
                                      id=""
                                    >
                                      <option value="کیفیت صادراتی">
                                        کیفیت صادراتی
                                      </option>
                                      <option value="کیفیت متوسط پر مصرف">
                                        کیفیت متوسط پر مصرف
                                      </option>
                                      <option value="کیفیت درجه یک داخلی">
                                        کیفیت درجه یک داخلی
                                      </option>
                                      <option value="کیفیت معمولی">
                                        کیفیت معمولی
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for="">
                                    توضیحات درجه بندی محصولات
                                  </label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="
                                      dataItemEnIndustrialFour.descDegreePro
                                    "
                                  ></textarea>
                                </div>

                                <div class="col-12">
                                  <label for="">
                                    میزان تولید در ماه یا سال
                                  </label>
                                  <textarea
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    v-model="
                                      dataItemEnIndustrialFour.tolidYearMonth
                                    "
                                  ></textarea>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">شرایط فروش</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="
                                        dataItemEnIndustrialFour.sharayetFrosh
                                      "
                                      id=""
                                    >
                                      <option value="نقدی">نقدی</option>
                                      <option value="ضمانت نامه">
                                        ضمانت نامه
                                      </option>
                                      <option value="تهاتر (ملک ...)">
                                        تهاتر (ملک ...)
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for="">توضیحات شرایط فروش</label>
                                  <div class="form-group">
                                    <textarea
                                      name=""
                                      v-model="dataItemEnIndustrialFour.descFro"
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for="">
                                    پیشنهاد درصد تخفیف یا دستمزد کارگزاری فروش
                                  </label>
                                  <div class="form-group">
                                    <textarea
                                      name=""
                                      v-model="
                                        dataItemEnIndustrialFour.suggestDisCount
                                      "
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">سابقه صادرات</label>

                                    <textarea
                                      name=""
                                      v-model="
                                        dataItemEnIndustrialFour.saderatHistory
                                      "
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                      placeholder="مثال :  بله یا خیر در صورت بله بودن نام کشور هارو بنویسید در غیر این صورت بنویسید که تمایل صادرات دارید"
                                    ></textarea>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">درخواست مشاوره</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="
                                        dataItemEnIndustrialFour.moshavere
                                      "
                                      id=""
                                    >
                                      <option value="فروش کارخانه">
                                        فروش کارخانه
                                      </option>
                                      <option
                                        value="جذب سرمایه گذار برای توسعه"
                                      >
                                        جذب سرمایه گذار برای توسعه
                                      </option>
                                      <option
                                        value="راه اندازی و تعمیرات اساسی (overhaul)"
                                      >
                                        راه اندازی و تعمیرات اساسی (overhaul)
                                      </option>
                                      <option value="افزایش خط یا ظرفیت تولید">
                                        افزایش خط یا ظرفیت تولید
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">توضیحات مشاوره</label>
                                    <textarea
                                      name=""
                                      v-model="
                                        dataItemEnIndustrialFour.descMoshavere
                                      "
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""
                                      >روش خرید مواد اولیه برای کارخانه یا واحد
                                      تولیدی</label
                                    >
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="
                                        dataItemEnIndustrialFour.sharayetFroshTolidi
                                      "
                                      id=""
                                    >
                                      <option value="نقدی">نقدی</option>
                                      <option value="ضمانت نامه">
                                        ضمانت نامه
                                      </option>
                                      <option value="تهاتر (ملک ...)">
                                        تهاتر (ملک ...)
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""
                                    >توضیحات روش خرید مواد اولیه برای کارخانه یا
                                    واحد تولیدی
                                  </label>
                                  <div class="form-group">
                                    <textarea
                                      name=""
                                      v-model="
                                        dataItemEnIndustrialFour.descFroTolidi
                                      "
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">هزینه ی حمل و نقل</label>
                                    <textarea
                                      name=""
                                      v-model="
                                        dataItemEnIndustrialFour.priceTrans
                                      "
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                      placeholder="برای مثال : نمیپذیرم یا میپذیرم در شهرهای تهران ، اصفهان و ..."
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""
                                      >در زمینه برندینگ و تبلیغات کدام یکی از
                                      روش های زیر را استفاده کرده اید</label
                                    >
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="
                                        dataItemEnIndustrialFour.branding
                                      "
                                      id=""
                                    >
                                      <option value="فضای مجازی">
                                        فضای مجازی
                                      </option>
                                      <option value="نمایندگی ها">
                                        نمایندگی ها
                                      </option>
                                      <option value="سایت">سایت</option>
                                      <option value="عکاسی و فیلم برداری">
                                        عکاسی و فیلم برداری
                                      </option>
                                      <option value="بیلبورد">بیلبورد</option>
                                      <option
                                        value="انجام نشده درخواست برندینگ دارم"
                                      >
                                        انجام نشده درخواست برندینگ دارم
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <ButtonLoading
                                    class="btn-cus mt-4"
                                    :showLoading="showLoading"
                                    text="ثبت"
                                    @click="submitForm()"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- املاک -->
                    <div
                      v-show="workDataItem.numberOfWork === 3"
                      class="col-12"
                    >
                      <div class="row d-flex justify-content-center">
                        <div class="col-lg-6 col-12">
                          <div class="form-group">
                            <label for=""
                              >حوزه مورد نظر خود را انتخاب کنید</label
                            >
                            <select
                              v-model="workDataItem.amlakWorkSelectNumber"
                              name=""
                              class="form-control py-0"
                              id=""
                            >
                              <option :value="1">
                                درخواست خرید مواد معدنی و مصالح ساختمانی
                              </option>
                              <option :value="2">
                                استفاده از خدمات و شرکت آرا
                              </option>
                              <option :value="3">
                                درخواست حضور در پلتفرم به عنوان فروشنده انواع
                                ملک
                              </option>
                              <option :value="4">
                                تمامی موارد (دارای شرایط ویژه همکاری)
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div
                            v-if="workDataItem.amlakWorkSelectNumber === 1"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""> سمت فرد متقاضی </label>
                                  <select
                                    v-model="dataItemEnBuildingOne.position"
                                    name=""
                                    class="form-control"
                                    id=""
                                  >
                                    <option value="مالک">مالک</option>
                                    <option value="پیمانکار">پیمانکار</option>
                                  </select>
                                </div>

                                <div class="col-12">
                                  <label for=""> آدرس پروژه</label>
                                  <textarea
                                    v-model="
                                      dataItemEnBuildingOne.addressProject
                                    "
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                  ></textarea>
                                </div>

                                <div class="col-12">
                                  <label for=""
                                    >پروژه شما در چه مرحله ای میباشد</label
                                  >
                                  <textarea
                                    v-model="dataItemEnBuildingOne.projectLevel"
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                  ></textarea>
                                </div>

                                <div class="col-12">
                                  <label for="">دیگر توضیحات مدنظر شما</label>
                                  <textarea
                                    v-model="dataItemEnBuildingOne.descMad"
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                  ></textarea>
                                </div>

                                <div class="col-12">
                                  <label for="">روش آشنایی با آرا</label>
                                  <textarea
                                    v-model="dataItemEnBuildingOne.ashnaiiAra"
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <ButtonLoading
                                    class="btn-cus mt-4"
                                    :showLoading="showLoading"
                                    text="ثبت"
                                    @click="submitForm()"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="workDataItem.amlakWorkSelectNumber === 2"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-inline">
                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="
                                        dataItemEnBuildingTwo.servicesArray
                                      "
                                      value="تیم مهندسی و معماری"
                                    />
                                    <label class="form-check-label">
                                      تیم مهندسی و معماری
                                    </label>

                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="
                                        dataItemEnBuildingTwo.servicesArray
                                      "
                                      value="تیم مشاوره اقتصادی و ملکی"
                                    />
                                    <label class="form-check-label">
                                      تیم مشاوره اقتصادی و ملکی
                                    </label>

                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="
                                        dataItemEnBuildingTwo.servicesArray
                                      "
                                      value="تیم های اجرایی"
                                    />
                                    <label class="form-check-label">
                                      تیم های اجرایی
                                    </label>

                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="
                                        dataItemEnBuildingTwo.servicesArray
                                      "
                                      value="تبلیغات و برندینگ"
                                    />
                                    <label class="form-check-label">
                                      تبلیغات و برندینگ
                                    </label>

                                    <input
                                      class="form-check-input mx-2"
                                      type="checkbox"
                                      v-model="
                                        dataItemEnBuildingTwo.servicesArray
                                      "
                                      value="جذب سرمایه برای توسعه"
                                    />
                                    <label class="form-check-label">
                                      جذب سرمایه برای توسعه
                                    </label>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <ButtonLoading
                                    class="btn-cus mt-4"
                                    :showLoading="showLoading"
                                    text="ثبت"
                                    @click="submitForm()"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="workDataItem.amlakWorkSelectNumber === 3"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""
                                      >املاک مد نظر خود برای فروش</label
                                    >
                                    <textarea
                                      v-model="dataItemEnBuildingThree.typeMelk"
                                      name=""
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                      placeholder="نوع ملک : تجاری اداری ، مسکونی و..."
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""
                                      >اعلام مراحل ساخت در صورت تکمیل نشدن پیش
                                      فروش</label
                                    >
                                    <textarea
                                      v-model="
                                        dataItemEnBuildingThree.levelBuild
                                      "
                                      name=""
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">آدرس ملک برای فروش</label>
                                    <textarea
                                      v-model="dataItemEnBuildingThree.address"
                                      name=""
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <ButtonLoading
                                    class="btn-cus mt-4"
                                    :showLoading="showLoading"
                                    text="ثبت"
                                    @click="submitForm()"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="workDataItem.amlakWorkSelectNumber === 4"
                            class="row d-flex justify-content-center"
                          >
                            <div class="col-lg-6 col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for=""
                                          >نام و نام خانوادگی
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="name"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">شماره تماس </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="mobile"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">ایمیل </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="email"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">استان </label>
                                        <select
                                          class="form-control"
                                          v-model="province"
                                        >
                                          <option
                                            v-for="(
                                              item, index
                                            ) in dataList.province"
                                            :key="index + 'provinces'"
                                            :value="item.id"
                                          >
                                            {{ item.title }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label for="">آدرس </label>
                                        <textarea
                                          name=""
                                          class="form-control"
                                          v-model="address"
                                          id=""
                                          cols="30"
                                          rows="10"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""> سمت فرد متقاضی </label>
                                    <select
                                      v-model="dataItemEnBuildingFour.position"
                                      name=""
                                      class="form-control"
                                      id=""
                                    >
                                      <option value="مالک">مالک</option>
                                      <option value="پیمانکار">پیمانکار</option>
                                      <option value="وکیل قانونی">
                                        وکیل قانونی
                                      </option>
                                      <option value="سازنده مشارکت">
                                        سازنده مشارکت
                                      </option>
                                      <option value="مشاوره پروژه">
                                        مشاوره پروژه
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <label for=""> آدرس فرد متقاضی </label>
                                  <textarea
                                    v-model="dataItemEnBuildingFour.address"
                                    name=""
                                    class="form-control"
                                    id=""
                                    cols="30"
                                    rows="10"
                                  ></textarea>
                                </div>
                                <div class="col-12">
                                  <label for=""> متراژ زمین </label>
                                  <input
                                    type="text"
                                    v-model="dataItemEnBuildingFour.metrazh"
                                    class="form-control"
                                  />
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label>جواز ساخت</label>
                                    <select
                                      name=""
                                      v-model="dataItemEnBuildingFour.javaz"
                                      class="form-control"
                                      id=""
                                    >
                                      <option value="دارد">دارد</option>
                                      <option value="ندارد">ندارد</option>
                                      <option value="در دست اقدام">
                                        در دست اقدام
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label>کاربری</label>
                                    <select
                                      name=""
                                      v-model="dataItemEnBuildingFour.karbari"
                                      class="form-control"
                                      id=""
                                    >
                                      <option value="مسکونی">مسکونی</option>
                                      <option value="تجاری">تجاری</option>
                                      <option value="ویلایی">ویلایی</option>
                                      <option value="صنعتی">صنعتی</option>
                                      <option value="اداری">اداری</option>
                                      <option value="خدماتی">خدماتی</option>
                                      <option value="سایر">سایر</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""> توضیحات کاربری </label>
                                    <textarea
                                      v-model="
                                        dataItemEnBuildingFour.descKarbari
                                      "
                                      name=""
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label>پروژه در چه مرحله ای میباشد</label>
                                    <select
                                      name=""
                                      v-model="
                                        dataItemEnBuildingFour.levelProject
                                      "
                                      class="form-control"
                                      id=""
                                    >
                                      <option value="تجهیز کارگاه">
                                        تجهیز کارگاه
                                      </option>
                                      <option value="فونداسیون">
                                        فونداسیون
                                      </option>
                                      <option value="سقف و ستون">
                                        سقف و ستون
                                      </option>
                                      <option value="تاسیسات">تاسیسات</option>
                                      <option value="نازک کاری">
                                        نازک کاری
                                      </option>
                                      <option value="آماده تحویل">
                                        آماده تحویل
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""> توضیحات مرحله پروژه </label>
                                    <textarea
                                      v-model="
                                        dataItemEnBuildingFour.descLevelPrj
                                      "
                                      name=""
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label
                                      >درخواست خود را در این مرحله اعلام
                                      کنید</label
                                    >
                                    <select
                                      name=""
                                      v-model="
                                        dataItemEnBuildingFour.requstKala
                                      "
                                      class="form-control"
                                      id=""
                                    >
                                      <option value="مصالح ساختمانی">
                                        مصالح ساختمانی
                                      </option>
                                      <option value="تیم معماری">
                                        تیم معماری
                                      </option>
                                      <option value="تیم پیمانکاری">
                                        تیم پیمانکاری
                                      </option>
                                      <option value="مشاور اجرا">
                                        مشاور اجرا
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""> توضیحات درخواست </label>
                                    <textarea
                                      v-model="dataItemEnBuildingFour.descReq"
                                      name=""
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">
                                      درخواست مصالح اقلام مورد نیاز
                                    </label>
                                    <textarea
                                      v-model="
                                        dataItemEnBuildingFour.reqMasaleh
                                      "
                                      name=""
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">شرایط فروش</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="
                                        dataItemEnBuildingFour.sharayetFrosh
                                      "
                                      id=""
                                    >
                                      <option value="نقدی">نقدی</option>
                                      <option value="ضمانت نامه">
                                        ضمانت نامه
                                      </option>
                                      <option value="تهاتر (ملک ...)">
                                        تهاتر (ملک ...)
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for="">توضیحات شرایط فروش</label>
                                  <div class="form-group">
                                    <textarea
                                      name=""
                                      v-model="dataItemEnBuildingFour.descFro"
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""
                                      >زمان تحویل اقلام مورد نیاز</label
                                    >
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="
                                        dataItemEnBuildingFour.timeTahvil
                                      "
                                      id=""
                                    >
                                      <option value="به روز">به روز</option>
                                      <option value="۱ ماهه">۱ ماهه</option>
                                      <option value="۳ ماهه">۳ ماهه</option>
                                      <option value="۶ ماهه">۶ ماهه</option>
                                    </select>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">درخواست تهاتر</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="dataItemEnBuildingFour.tahatar"
                                      id=""
                                    >
                                      <option value="از ملک در حال ساخت">
                                        از ملک در حال ساخت
                                      </option>
                                      <option
                                        value="واحد از پروژه دیگر یا ملک آماده"
                                      >
                                        واحد از پروژه دیگر یا ملک آماده
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""
                                    >توضیحات و آدرس درخواست تهاتر</label
                                  >
                                  <div class="form-group">
                                    <textarea
                                      name=""
                                      v-model="
                                        dataItemEnBuildingFour.descTahater
                                      "
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""> قیمت اعطامی </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="
                                        dataItemEnBuildingFour.priceEtami
                                      "
                                      name=""
                                      id=""
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">درخواست مهندسی فروش</label>
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="
                                        dataItemEnBuildingFour.mohandesiFro
                                      "
                                      id=""
                                    >
                                      <option value="پیش فروش">پیش فروش</option>
                                      <option value="فروش">فروش</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""
                                      >شرایط دریافت پول برای مشتری یا سرمایه
                                      گذار</label
                                    >
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="
                                        dataItemEnBuildingFour.sharayetDaryaft
                                      "
                                      id=""
                                    >
                                      <option value="نقدی">نقدی</option>
                                      <option value="ضمانت نامه">
                                        ضمانت نامه
                                      </option>
                                      <option value="تهاتر">تهاتر</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""
                                    >توضیحات شرایط دریافت پول برای مشتری یا
                                    سرمایه گذار</label
                                  >
                                  <div class="form-group">
                                    <textarea
                                      name=""
                                      v-model="
                                        dataItemEnBuildingFour.descSharayet
                                      "
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""
                                    >پیشنهاد درصد کارگذاری بخش مشاوره و
                                    فروش</label
                                  >
                                  <div class="form-group">
                                    <textarea
                                      name=""
                                      v-model="
                                        dataItemEnBuildingFour.suggestionDarsad
                                      "
                                      class="form-control"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""
                                    >درخواست مشارکت ملک جدید (شهر)</label
                                  >
                                  <div class="form-group">
                                    <input
                                      v-model="dataItemEnBuildingFour.city"
                                      class="form-control"
                                      placeholder="مثال : تهران"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""
                                    >درخواست مشارکت ملک جدید (منطقه)</label
                                  >
                                  <div class="form-group">
                                    <input
                                      v-model="dataItemEnBuildingFour.mantaghe"
                                      class="form-control"
                                      placeholder="مثال : تهران"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for=""
                                    >درخواست مشارکت ملک جدید (متراژ)</label
                                  >
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        dataItemEnBuildingFour.metrazhMan
                                      "
                                      class="form-control"
                                      placeholder="مثال : تهران"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label for="">شرایط مشارکت ملک جدید</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        dataItemEnBuildingFour.sharayetNewMelk
                                      "
                                      class="form-control"
                                      placeholder="مثال : تهران"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for=""
                                      >در زمینه برندینگ و تبلیغات کدام یکی از
                                      روش های زیر را استفاده کرده اید</label
                                    >
                                    <select
                                      name=""
                                      class="form-control"
                                      v-model="dataItemEnBuildingFour.branding"
                                      id=""
                                    >
                                      <option value="فضای مجازی">
                                        فضای مجازی
                                      </option>
                                      <option value="نمایندگی ها">
                                        نمایندگی ها
                                      </option>
                                      <option value="سایت">سایت</option>
                                      <option value="عکاسی و فیلم برداری">
                                        عکاسی و فیلم برداری
                                      </option>
                                      <option value="بیلبورد">بیلبورد</option>
                                      <option
                                        value="انجام نشده درخواست برندینگ دارم"
                                      >
                                        انجام نشده درخواست برندینگ دارم
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <ButtonLoading
                                    class="btn-cus mt-4"
                                    :showLoading="showLoading"
                                    text="ثبت"
                                    @click="submitForm()"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- طرح توجیحی -->
                    <div
                      v-show="workDataItem.numberOfWork === 4"
                      class="col-12"
                    >
                      <div class="row d-flex justify-content-center">
                        <div class="col-12 col-lg-6">
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">نام و نام خانوادگی </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="name"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">شماره تماس </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="mobile"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">ایمیل </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="email"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label for="">آدرس </label>
                                    <textarea
                                      name=""
                                      class="form-control"
                                      v-model="address"
                                      id=""
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <label for=""> سمت فرد متقاضی </label>
                              <select
                                v-model="dataItemEnTojihi.position"
                                name=""
                                class="form-control"
                                id=""
                              >
                                <option value="صاحب سرمایه">صاحب سرمایه</option>
                                <option value="وکیل">وکیل</option>
                                <option value="مشاور اقتصادی">
                                  مشاور اقتصادی
                                </option>
                              </select>
                            </div>
                            <div class="col-12">
                              <label for="">نوع سرمایه یا دارایی</label>
                              <select
                                v-model="dataItemEnTojihi.sarmaye"
                                name=""
                                class="form-control"
                                id=""
                              >
                                <option value="نقدی">نقدی</option>
                                <option value="دارایی منقول">
                                  دارایی منقول
                                </option>
                                <option value="دارایی غیر منقول">
                                  دارایی غیر منقول
                                </option>
                              </select>
                            </div>

                            <div class="col-12">
                              <label for="">توضیحات</label>
                              <textarea
                                v-model="dataItemEnTojihi.decription"
                                name=""
                                class="form-control"
                                id=""
                                cols="30"
                                rows="10"
                              ></textarea>
                            </div>

                            <div class="col-12">
                              <label for="">بستر سرمایه گذاری مدنظر شما</label>
                              <select
                                v-model="dataItemEnTojihi.bastar"
                                name=""
                                class="form-control"
                                id=""
                              >
                                <option value="کارخانه و تولیدی ها">
                                  کارخانه و تولیدی ها
                                </option>
                                <option value="معادن">معادن</option>
                                <option value="املاک">املاک</option>
                                <option value="بازرگانی">بازرگانی</option>
                              </select>
                            </div>
                            <div class="col-12">
                              <ButtonLoading
                                class="btn-cus mt-4"
                                :showLoading="showLoading"
                                text="ثبت"
                                @click="submitForm()"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NavbarHeader v-model="showSidebar" :showMenu="showMenu" />
    <Sidebar
      :showSidebar="showSidebar"
      :sidebarListItem="filteredSidebarList"
    />
    <main id="content" role="main" class="main">
      <div class="content container-fluid">
        <router-view :key="$route.fullPath" />
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import getUserAgentDetails from "@/utils/getUserAgentDetails";
import NavbarHeader from "@/components/manager/NavbarHeader.vue";

import Sidebar from "@/components/manager/Sidebar.vue";
import { checkAuth } from "@/utils/checkAuth";
import axios from "axios";
import { toastSuccess, toastError } from "@/utils/toast";
export default {
  name: "PanelManager",
  components: {
    NavbarHeader,
    Sidebar,
  },

  data() {
    return {
      userRole: "",
      dataList: [],
      activeIndex: null,
      chooseAll: "",
      showLoading: false,
      // معدن
      dataItemEnMineOne: {
        companyName: "",
        position: "",
        address: "",
      },
      dataItemEnMineTwo: {
        services: "",
        servicesArray: [],
      },
      dataItemEnMineThree: {
        companyName: "",
        position: "",
        address: "",
        officeAddress: "",
        qualityList: "",
        countTolid: "",
      },
      dataItemEnMineFour: {
        companyName: "",
        position: "",
        address: "",
        officeAddress: "",
        qualityList: "",
        countTolid: "",
        onsorAsli: "",
        onsorFarii: "",

        parvanehEk: "",
        parvanehBah: "",
        mineBahreh: "",
        mineSystemKhar: "",
        mineSystemEstehsal: "",
        goalBazar: "",
        sharayetFrosh: "",
        descFro: "",
        suggestDisCount: "",
        saderatHistory: "",
        moshavere: "",
        descMoshavere: "",
        priceTrans: "",
        branding: "",
      },
      // صنعت
      dataItemEnIndustrialOne: {
        position: "",
        type: "",
        address: "",
      },

      dataItemEnIndustrialTwo: {
        services: "",
        servicesArray: [],
      },

      dataItemEnIndustrialThree: {
        position: "",
        address: "",
        addressMarkaz: "",
        addressAnbar: "",
        productList: "",
        tolidcount: "",
        transPort: "",
      },

      dataItemEnIndustrialFour: {
        position: "",
        nameBrand: "",
        addressTolid: "",
        tolidNumber: "",
        addressMark: "",
        markazNumber: "",
        addressNama: "",
        NamaNumber: "",
        foroshProvince: "",
        degreeProduct: "",
        descDegreePro: "",
        tolidYearMonth: "",
        sharayetFrosh: "",
        descFro: "",
        suggestDisCount: "",
        saderatHistory: "",
        moshavere: "",
        descMoshavere: "",
        sharayetFroshTolidi: "",
        descFroTolidi: "",
        priceTrans: "",
        branding: "",
      },
      // املاک

      dataItemEnBuildingOne: {
        position: "",
        addressProject: "",
        projectLevel: "",
        descMad: "",
        ashnaiiAra: "",
      },
      dataItemEnBuildingTwo: {
        services: "",
        servicesArray: [],
      },
      dataItemEnBuildingThree: { typeMelk: "", levelBuild: "", address: "" },
      dataItemEnBuildingFour: {
        position: "",
        address: "",
        metrazh: "",
        javaz: "",
        karbari: "",
        descKarbari: "",
        levelProject: "",
        descLevelPrj: "",
        requstKala: "",
        descReq: "",
        reqMasaleh: "",
        sharayetFrosh: "",
        descFro: "",
        timeTahvil: "",
        tahatar: "",
        descTahater: "",
        priceEtami: "",
        mohandesiFro: "",
        sharayetDaryaft: "",
        descSharayet: "",
        suggestionDarsad: "",
        city: "",
        mantaghe: "",
        metrazhMan: "",
        sharayetNewMelk: "",
        branding: "",
      },

      dataItemEnTojihi: {
        position: "",
        decription: "",
        sarmaye: "",
        bastar: "",
      },

      workDataItem: {
        numberOfWork: null,
        minWorkSelectNumber: null,
        amlakWorkSelectNumber: null,
        industrialWorkSelectNumber: null,
      },
      allFourWorkType: {
        oneMineWork: false,
      },

      // اطلاعات فردی
      name: "",
      mobile: "",
      email: "",
      address: "",
      province: "",

      userAgentDetails: {},
      showSidebar: false,
      workCategorySection: true,
      displayNoneMobile: false,
      showMenu: false,

      allQuestionAfterJobCaterogy: false,
      typeContract: "",
      showLoadingJobCategory: false,
      finalCatWorkToSend: "",
      finalMineSelectTitle: "",
      finalIndustrialeSelectTitle: "",
      finalBuildingSelectTitle: "",
      selectedTitleToCheck: "",

      sidebarListItem: [
        {
          type: "span",
          title: "manager_dashboard",
        },
        {
          title: "dashboard",
          icon: "bi-house ",
          rule: ["admin", "agent", "customer"],
          url: {
            type: "name",
            url: "PanelManager-Home",
          },
        },

        {
          title: "درخواست ها",
          icon: "bi-arrow-up-right-square",
          rule: ["admin", "agent", "customer"],
          url: {
            type: "name",
            url: "PanelManager-Requests",
          },
        },

        {
          title: "آگهی",
          icon: "bi-book",
          rule: ["admin", "agent", "customer"],
          url: {
            type: "name",
            url: "PanelManager-Products-New",
          },
        },

        {
          title: "پشتیبانی",
          icon: " bi-activity",
          rule: ["admin", "agent", "customer"],
          url: {
            type: "name",
            url: "PanelManager-Tickets-New",
          },
        },

        // {
        //   title: "فرم ها",
        //   icon: "bi-ethernet ",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Forms-Mine",
        //   },
        // },

        /*
        {
          title: "devices",
          icon: "bi-ethernet ",
          url: {
            type: "name",
            url: "PanelManager-Devices",
          },
        },
        {
          title: "zones",
          icon: "bi-geo-fill ",
          url: {
            type: "name",
            url: "PanelManager-Zones",
          },
        },

        {
          title: "events",
          icon: " bi-clipboard-plus",
          url: {
            type: "name",
            url: "PanelManager-Events",
          },
        },
        */

        // {
        //   title: "events",
        //   icon: " bi-clipboard-plus",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Events",
        //   },
        // },

        // {
        //   title: "ثبت نامی",
        //   icon: " bi-person-plus",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-EventRegistered",
        //   },
        // },

        // {
        //   title: "تقویم",
        //   icon: " bi-calendar-date",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Calender",
        //   },
        // },

        // {
        //   title: "نقشه فناوری",
        //   icon: " bi-pin",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Qsciencemap",
        //   },
        // },
        // {
        //   title: "blog",
        //   icon: " bi-body-text",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-BlogList",
        //   },
        // },

        // {
        //   title: "پادکست",
        //   icon: " bi-mic",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Podcast-List",
        //   },
        // },

        // {
        //   title: "ویدیو",
        //   icon: " bi-film",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Video-List",
        //   },
        // },

        // {
        //   title: "تراکنش ها",
        //   icon: " bi-clipboard-plus",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Transactions",
        //   },
        // },

        // {
        //   title: "نویسنده ها",

        //   icon: " bi-pencil",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Writer",
        //   },
        // },

        // {
        //   title: "بازی ها",

        //   icon: "bi-controller",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Games",
        //   },
        // },

        // {
        //   title: "وبینار",

        //   icon: "bi-file-earmark-play",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Webinar",
        //   },
        // },

        // {
        //   title: "users",
        //   icon: "bi-people",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Users",
        //   },
        // },

        // {
        //   title: "upload_center",
        //   icon: " bi-cloud-upload",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Uploadcenter",
        //   },
        // },
        // {
        //   title: "کدتخفیف",
        //   icon: " bi-tag",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-Discounts",
        //   },
        // },

        // {
        //   title: "analytics",
        //   icon: "bi-graph-up-arrow",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-analytics",
        //   },
        // },

        /*
        {
          title: "monitoring",
          icon: "bi-display",
          url: {
            type: "name",
            url: "PanelManager-Monitoring",
          },
        },



        {
          title: "history",
          icon: "bi-clock-history ",
          url: {
            type: "name",
            url: "PanelManager-History-Index",
          },
        },

        {
          title: "setting",
          icon: "bi-gear ",
          url: {
            type: "name",
            url: "PanelManager-Preferences-Index",
          },
        },
        */

        {
          type: "span",
          title: "user",
          rule: ["admin", "agent", "customer"],
        },

        /*
        {
          title: "operators",
          icon: "bi-person ",
          url: {
            type: "name",
            url: "PanelManager-Managers",
          },
        },


        {
          title: "managers",
          icon: "bi-person ",
          url: {
            type: "name",
            url: "PanelManager-Managers",
          },
        },
*/

        {
          title: "profile",
          rule: ["admin", "agent", "customer"],
          icon: "bi-person ",
          url: {
            type: "name",
            url: "PanelManager-Profile",
          },
        },
        {
          title: "sign_out",
          rule: ["admin", "agent", "customer"],
          icon: "bi-box-arrow-right ",
          url: {
            type: "name",
            url: "PanelManager-Logout",
          },
        },
      ],
      boxRoForm: true,
    };
  },
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    toggleChildren(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },

    getDataProvince() {
      this.loading = true;
      const url = "/data";
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .post(url, {}, config)
        .then((response) => {
          this.loading = false;
          const serverRespone = response.data;
          this.dataList = serverRespone.data;
        })
        .catch((error) => {});
    },
    checkUserDataa() {},

    getUserData(token) {
      let url = "/auth/me";
      this.$store.commit("readManagerToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
          // Authorization: token,
        },
      };

      axios
        .post(url, {}, config)
        .then((response) => {
          const serverResponse = response.data;
          console.log(serverResponse.rule);
          this.userRole = serverResponse.rule;
          this.selectedTitleToCheck = serverResponse.selected_title;
          console.log(this.selectedTitleToCheck);

          if (this.selectedTitleToCheck.length > 5) {
            this.displayNoneMobile = true;
          }
        })
        .catch((error) => {
          this.showLoadingJobCategory = false;
        });
    },

    submitNormalUser() {
      if (
        !this.showLoading &&
        this.name.length > 0 &&
        this.email.length > 0 &&
        this.mobile.length > 0 &&
        this.address.length > 0
      ) {
        this.showLoading = true;
        let url = "/auth/edit";

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const data = JSON.stringify({
          name: this.name,
          address: this.address,
          email: this.email,
          mobile: this.mobile,
        });
        axios
          .post(url, data, config)
          .then((response) => {
            const serverResponse = response.data;
            this.displayNoneMobile = true;
            this.showLoading = false;
            toastSuccess(serverResponse.message);
          })
          .catch((error) => {
            this.showLoading = false;
            toastError(error.response.data.message);
          });
      } else {
        toastError("لطفا تمامی فیلدها را پر کنید");
      }
    },
    async submitJobCategory() {
      this.$store.commit("readManagerToken");
      if (!this.showLoadingJobCategory) {
        this.showLoadingJobCategory = true;
        let url = "/auth/edit";

        if (this.workDataItem.numberOfWork === 1) {
          this.$store.commit("readManagerToken");
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + this.$store.state.managerToken,
            },
          };
          const data = JSON.stringify({
            type_contract: "mine",
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.showLoadingJobCategory = false;
              this.allQuestionAfterJobCaterogy = true;
              this.workCategorySection = false;
            })
            .catch((error) => {
              this.showLoadingJobCategory = false;
            });
        } else if (this.workDataItem.numberOfWork === 2) {
          this.$store.commit("readManagerToken");
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + this.$store.state.managerToken,
            },
          };
          const data = JSON.stringify({
            type_contract: "industry",
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.showLoadingJobCategory = false;
              this.allQuestionAfterJobCaterogy = true;
              this.workCategorySection = false;
            })
            .catch((error) => {
              this.showLoadingJobCategory = false;
            });
        } else if (this.workDataItem.numberOfWork === 3) {
          this.$store.commit("readManagerToken");
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + this.$store.state.managerToken,
            },
          };
          const data = JSON.stringify({
            type_contract: "building",
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.showLoadingJobCategory = false;
              this.allQuestionAfterJobCaterogy = true;
              this.workCategorySection = false;
            })
            .catch((error) => {
              this.showLoadingJobCategory = false;
            });
        } else if (this.workDataItem.numberOfWork === 4) {
          this.$store.commit("readManagerToken");
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + this.$store.state.managerToken,
            },
          };
          const data = JSON.stringify({
            type_contract: "sarmaye",
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.showLoadingJobCategory = false;
              this.allQuestionAfterJobCaterogy = true;
              this.workCategorySection = false;
            })
            .catch((error) => {
              this.showLoadingJobCategory = false;
            });
        }
      }
    },
    submitForm() {
      if (!this.showLoading) {
        this.showLoading = true;
        let url = "/auth/edit";

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        if (this.workDataItem.numberOfWork === 1) {
          this.finalCatWorkToSend = "mine";
        } else if (this.workDataItem.numberOfWork === 2) {
          this.finalCatWorkToSend = "industry";
        } else if (this.workDataItem.numberOfWork === 3) {
          this.finalCatWorkToSend = "building";
        } else if (this.workDataItem.numberOfWork === 4) {
          this.finalCatWorkToSend = "sarmaye";
        }

        // معدن

        if (this.workDataItem.minWorkSelectNumber === 1) {
          this.finalMineSelectTitle = "خرید ماشین آلات و ابزار مورد نیاز";
        } else if (this.workDataItem.minWorkSelectNumber === 2) {
          this.finalMineSelectTitle = "استفاده از خدمات و مشاوره آرا";
        } else if (this.workDataItem.minWorkSelectNumber === 3) {
          this.finalMineSelectTitle =
            "درخواست حضور به عنوان فروشنده مواد معدنی";
        } else if (this.workDataItem.minWorkSelectNumber === 4) {
          this.finalMineSelectTitle = "تمامی موارد (دارای شرایط ویژه همکاری)";
        }

        // صنعت

        if (this.workDataItem.industrialWorkSelectNumber === 1) {
          this.finalIndustrialeSelectTitle =
            "درخواست خرید مواد اولیه کارخانه خود از معدن";
        } else if (this.workDataItem.industrialWorkSelectNumber === 2) {
          this.finalIndustrialeSelectTitle = "استفاده از خدمات و مشاوره آرا";
        } else if (this.workDataItem.industrialWorkSelectNumber === 3) {
          this.finalIndustrialeSelectTitle =
            "درخواست حضور به عنوان فروشنده لوازم صنعتی";
        } else if (this.workDataItem.industrialWorkSelectNumber === 4) {
          this.finalIndustrialeSelectTitle =
            "تمامی موارد (دارای شرایط ویژه همکاری)";
        }

        // املاک

        if (this.workDataItem.amlakWorkSelectNumber === 1) {
          this.finalBuildingSelectTitle =
            "درخواست خرید مواد معدنی و مصالح ساختمانی";
        } else if (this.workDataItem.amlakWorkSelectNumber === 2) {
          this.finalBuildingSelectTitle = "استفاده از خدمات و مشاوره آرا";
        } else if (this.workDataItem.amlakWorkSelectNumber === 3) {
          this.finalBuildingSelectTitle =
            "درخواست حضور در پلتفرم به عنوان فروشنده و انواع ملک";
        } else if (this.workDataItem.amlakWorkSelectNumber === 4) {
          this.finalBuildingSelectTitle =
            "تمامی موارد (دارای شرایط ویژه همکاری)";
        }

        // معدن

        const dataItemMineOne = {
          "نام شرکت": this.dataItemEnMineOne.companyName,
          "سمت فرد متقاضی": this.dataItemEnMineOne.position,
          "آدرس واحد معدنی": this.dataItemEnMineOne.address,
        };
        const dataItemMineTwo = {
          خدمات: this.dataItemEnMineTwo.services,
        };
        const dataItemMineThree = {
          "نام شرکت": this.dataItemEnMineThree.companyName,
          "سمت فرد متقاضی": this.dataItemEnMineThree.position,
          "آدرس واحد معدنی": this.dataItemEnMineThree.address,
          "آدرس دفتر مرکزی": this.dataItemEnMineThree.officeAddress,
          "لیست تولیدات و کیفیت": this.dataItemEnMineThree.qualityList,
          "میزان تولید در سال": this.dataItemEnMineThree.countTolid,
        };
        const dataItemMineFour = {
          "نام شرکت": this.dataItemEnMineFour.companyName,
          "سمت فرد متقاضی": this.dataItemEnMineFour.position,
          "آدرس واحد معدنی": this.dataItemEnMineFour.address,
          "آدرس دفتر مرکزی": this.dataItemEnMineFour.officeAddress,
          "لیست تولیدات و کیفیت": this.dataItemEnMineFour.qualityList,
          "میزان تولید در سال": this.dataItemEnMineFour.countTolid,

          "پروانه اکتشاف": this.dataItemEnMineFour.parvanehEk,
          "پروانه بهره برداری": this.dataItemEnMineFour.parvanehBah,
          "معدن در حال بهره برداری": this.dataItemEnMineFour.mineBahreh,
          "معدن سیستم خردایش": this.dataItemEnMineFour.mineSystemKhar,
          "معدن سیستم استحصال": this.dataItemEnMineFour.mineSystemEstehsal,
          "بازار هدف": this.dataItemEnMineFour.goalBazar,
          "شرایط فروش": this.dataItemEnMineFour.sharayetFrosh,
          "توضیحات شرایط فروش": this.dataItemEnMineFour.descFro,
          "پیشنهاد درصد تخفیف یا دستمزد کارگزاری فروش مواد معدنی":
            this.dataItemEnMineFour.suggestDisCount,
          "سابقه صادرات": this.dataItemEnMineFour.saderatHistory,
          "درخواست مشاوره": this.dataItemEnMineFour.moshavere,
          "توضیحات مشاوره": this.dataItemEnMineFour.descMoshavere,
          "هزینه ی حمل و نقل": this.dataItemEnMineFour.priceTrans,
          "در زمینه برندینگ و تبلیغات کدام یکی از روش های زیر را استفاده کرده اید":
            this.dataItemEnMineFour.branding,

          "عناصر اصلی": this.dataItemEnMineFour.onsorAsli,
          "عناصر فرعی": this.dataItemEnMineFour.onsorFarii,
        };

        // صنعت

        const dataItemIndustrialOne = {
          "نوع مواد اولیه و میزان درخواست سالیانه":
            this.dataItemEnIndustrialOne.type,
          "سمت فرد متقاضی": this.dataItemEnIndustrialOne.position,
          "آدرس کارخانه": this.dataItemEnIndustrialOne.address,
        };

        const dataItemIndustrialTwo = {
          خدمات: this.dataItemEnIndustrialTwo.services,
        };

        const dataItemIndustrialThree = {
          "سمت فرد متقاضی": this.dataItemEnIndustrialThree.position,
          "آدرس کارخانه (واحد تولید)": this.dataItemEnIndustrialThree.address,
          "آدرس دفتر مرکزی": this.dataItemEnIndustrialThree.addressMarkaz,
          "آدرس انبار و نمایندگی": this.dataItemEnIndustrialThree.addressAnbar,
          "جدول لیست محصولات": this.dataItemEnIndustrialThree.productList,
          "میزان تولید در سال": this.dataItemEnIndustrialThree.tolidcount,
          "حمل و نقل": this.dataItemEnIndustrialThree.transPort,
        };

        const dataItemIndustrialFour = {
          "سمت فرد متقاضی": this.dataItemEnIndustrialFour.position,
          "نام برند یا مجموعه شرکت ها": this.dataItemEnIndustrialFour.nameBrand,
          "آدرس کارخانه (واحد تولید)":
            this.dataItemEnIndustrialFour.addressTolid,
          "شماره تلفن واحد تولید": this.dataItemEnIndustrialFour.tolidNumber,
          "آدرس دفتر مرکزی": this.dataItemEnIndustrialFour.addressMark,
          "شماره تلفن دفتر مرکزی": this.dataItemEnIndustrialFour.markazNumber,
          "آدرس نمایندگی ها": this.dataItemEnIndustrialFour.addressNama,
          "شماره تلفن نمایندگی ها": this.dataItemEnIndustrialFour.NamaNumber,
          "در کدام استان ها فروش بیشتری دارید":
            this.dataItemEnIndustrialFour.foroshProvince,
          "درجه بندی محصولات": this.dataItemEnIndustrialFour.degreeProduct,
          "توضیحات درجه بندی محصولات":
            this.dataItemEnIndustrialFour.descDegreePro,
          "میزان تولید در ماه یا سال":
            this.dataItemEnIndustrialFour.tolidYearMonth,
          "شرایط فروش": this.dataItemEnIndustrialFour.sharayetFrosh,
          "توضیحات شرایط فروش": this.dataItemEnIndustrialFour.descFro,
          "پیشنهاد درصد تخفیف یا دستمزد کارگزاری فروش":
            this.dataItemEnIndustrialFour.suggestDisCount,
          "سابقه صادرات": this.dataItemEnIndustrialFour.saderatHistory,
          "درخواست مشاوره": this.dataItemEnIndustrialFour.moshavere,
          "توضیحات مشاوره": this.dataItemEnIndustrialFour.descMoshavere,
          "روش خرید مواد اولیه برای کارخانه یا واحد تولیدی":
            this.dataItemEnIndustrialFour.sharayetFroshTolidi,
          "توضیحات روش خرید مواد اولیه برای کارخانه یا واحد تولیدی":
            this.dataItemEnIndustrialFour.descFroTolidi,
          "هزینه ی حمل و نقل": this.dataItemEnIndustrialFour.priceTrans,
          "در زمینه برندینگ و تبلیغات کدام یکی ازروش های زیر را استفاده کرده اید":
            this.dataItemEnIndustrialFour.branding,
        };

        // املاک

        const dataItemBuildingOne = {
          "سمت فرد متقاضی": this.dataItemEnBuildingOne.position,
          "آدرس پروژه": this.dataItemEnBuildingOne.addressProject,
          "پروژه شما در چه مرحله ای میباشد":
            this.dataItemEnBuildingOne.projectLevel,
          "دیگر توضیحات مدنظر شما": this.dataItemEnBuildingOne.descMad,
          "روش آشنایی با آرا": this.dataItemEnBuildingOne.ashnaiiAra,
        };
        const dataItemBuildingTwo = {
          خدمات: this.dataItemEnBuildingTwo.services,
        };
        const dataItemBuildingThree = {
          "املاک مد نظر خود برای فروش": this.dataItemEnBuildingThree.typeMelk,
          "اعلام مراحل ساخت در صورت تکمیل نشدن پیش فروش":
            this.dataItemEnBuildingThree.levelBuild,
          "آدرس ملک برای فروش": this.dataItemEnBuildingThree.address,
        };
        const dataItemBuildingFour = {
          "سمت فرد متقاضی": this.dataItemEnBuildingFour.position,
          "آدرس فرد متقاضی": this.dataItemEnBuildingFour.address,
          "متراژ زمین": this.dataItemEnBuildingFour.metrazh,
          "جواز ساخت": this.dataItemEnBuildingFour.javaz,
          کاربری: this.dataItemEnBuildingFour.karbari,
          "توضیحات کاربری": this.dataItemEnBuildingFour.descKarbari,
          "پروژه در چه مرحله ای میباشد":
            this.dataItemEnBuildingFour.levelProject,
          "توضیحات مرحله پروژه": this.dataItemEnBuildingFour.descLevelPrj,
          "درخواست خود را در این مرحله اعلام کنید":
            this.dataItemEnBuildingFour.requstKala,
          "توضیحات درخواست": this.dataItemEnBuildingFour.descReq,
          "درخواست مصالح اقلام مورد نیاز":
            this.dataItemEnBuildingFour.reqMasaleh,
          "شرایط فروش": this.dataItemEnBuildingFour.sharayetFrosh,
          "توضیحات شرایط فروش": this.dataItemEnBuildingFour.descFro,
          "زمان تحویل اقلام مورد نیاز": this.dataItemEnBuildingFour.timeTahvil,
          "درخواست تهاتر": this.dataItemEnBuildingFour.tahatar,
          "توضیحات و آدرس درخواست تهاتر":
            this.dataItemEnBuildingFour.descTahater,
          "قیمت اعطامی": this.dataItemEnBuildingFour.priceEtami,
          "درخواست مهندسی فروش": this.dataItemEnBuildingFour.mohandesiFro,
          "شرایط دریافت پول برای مشتری یا سرمایه گذار":
            this.dataItemEnBuildingFour.sharayetDaryaft,
          "توضیحات شرایط دریافت پول برای مشتری یا سرمایه گذار":
            this.dataItemEnBuildingFour.descSharayet,
          "پیشنهاد درصد کارگذاری بخش مشاوره و فروش":
            this.dataItemEnBuildingFour.suggestionDarsad,
          "درخواست مشارکت ملک جدید (شهر)": this.dataItemEnBuildingFour.city,
          "درخواست مشارکت ملک جدید (منطقه)":
            this.dataItemEnBuildingFour.mantaghe,
          "درخواست مشارکت ملک جدید (متراژ)":
            this.dataItemEnBuildingFour.metrazhMan,
          "شرایط مشارکت ملک جدید": this.dataItemEnBuildingFour.sharayetNewMelk,
          "در زمینه برندینگ و تبلیغات کدام یکی ازروش های زیر را استفاده کرده اید":
            this.dataItemEnBuildingFour.branding,
        };

        const dataItemTojihi = {
          "سمت فرد متقاضی": this.dataItemEnTojihi.position,
          توضیحات: this.dataItemEnTojihi.decription,
          "نوع سرمایه یا دارایی": this.dataItemEnTojihi.sarmaye,
          "بستر سرمایه گذاری مدنظر شما": this.dataItemEnTojihi.bastar,
        };

        if (this.workDataItem.minWorkSelectNumber === 1) {
          const data = JSON.stringify({
            text_contract: dataItemMineOne,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.minWorkSelectNumber,
            selected_title: this.finalMineSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
              toastError(error.response.data.message);
            });
        } else if (this.workDataItem.minWorkSelectNumber === 2) {
          const data = JSON.stringify({
            text_contract: dataItemMineTwo,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.minWorkSelectNumber,
            selected_title: this.finalMineSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        } else if (this.workDataItem.minWorkSelectNumber === 3) {
          const data = JSON.stringify({
            text_contract: dataItemMineThree,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.minWorkSelectNumber,
            selected_title: this.finalMineSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        } else if (this.workDataItem.minWorkSelectNumber === 4) {
          const data = JSON.stringify({
            text_contract: dataItemMineFour,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.minWorkSelectNumber,
            selected_title: this.finalMineSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        } else if (this.workDataItem.industrialWorkSelectNumber === 1) {
          const data = JSON.stringify({
            text_contract: dataItemIndustrialOne,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.industrialWorkSelectNumber,
            selected_title: this.finalIndustrialeSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        } else if (this.workDataItem.industrialWorkSelectNumber === 2) {
          const data = JSON.stringify({
            text_contract: dataItemIndustrialTwo,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.industrialWorkSelectNumber,
            selected_title: this.finalIndustrialeSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        } else if (this.workDataItem.industrialWorkSelectNumber === 3) {
          const data = JSON.stringify({
            text_contract: dataItemIndustrialThree,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.industrialWorkSelectNumber,
            selected_title: this.finalIndustrialeSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        } else if (this.workDataItem.industrialWorkSelectNumber === 4) {
          const data = JSON.stringify({
            text_contract: dataItemIndustrialFour,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.industrialWorkSelectNumber,
            selected_title: this.finalIndustrialeSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        } else if (this.workDataItem.amlakWorkSelectNumber === 1) {
          const data = JSON.stringify({
            text_contract: dataItemBuildingOne,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.amlakWorkSelectNumber,
            selected_title: this.finalBuildingSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        } else if (this.workDataItem.amlakWorkSelectNumber === 2) {
          const data = JSON.stringify({
            text_contract: dataItemBuildingTwo,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.amlakWorkSelectNumber,
            selected_title: this.finalBuildingSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        } else if (this.workDataItem.amlakWorkSelectNumber === 3) {
          const data = JSON.stringify({
            text_contract: dataItemBuildingThree,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.amlakWorkSelectNumber,
            selected_title: this.finalBuildingSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        } else if (this.workDataItem.amlakWorkSelectNumber === 4) {
          const data = JSON.stringify({
            text_contract: dataItemBuildingFour,
            type_contract: this.finalCatWorkToSend,
            selected_item: this.workDataItem.amlakWorkSelectNumber,
            selected_title: this.finalBuildingSelectTitle,

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        } else if (this.workDataItem.numberOfWork === 4) {
          const data = JSON.stringify({
            text_contract: dataItemTojihi,
            type_contract: this.finalCatWorkToSend,
            selected_item: 4,
            selected_title: "سرمایه گذاری و طرح توجیحی",

            name: this.name,
            address: this.address,
            email: this.email,
            mobile: this.mobile,
            province_id: this.province,
          });
          axios
            .post(url, data, config)
            .then((response) => {
              const serverResponse = response.data;
              this.displayNoneMobile = true;
              this.showLoading = false;
              toastSuccess(serverResponse.message);
            })
            .catch((error) => {
              this.showLoading = false;
            });
        }
      }
    },
    HandelGetUserAgentDetails() {
      this.userAgentDetails = getUserAgentDetails();
    },

    async checkAreaAccess() {
      this.$store.commit("readManagerToken");
      if (this.$store.state.managerToken) {
        const authResponse = await checkAuth(
          "/auth/me",
          this.$store.state.managerToken
        );

        this.sleep(100).then(() => {
          if (authResponse) {
            this.$store.commit(
              "setManagerToken",
              this.$store.state.managerToken
            );
            const redirect = this.$route.query.redirect;
            if (redirect) {
              this.$router.push(redirect).then((res) => {
                this.show = true;
                this.showPreLoad = false;
              });
            } else {
              this.$router.push(redirect).then((res) => {
                this.show = true;
                this.showPreLoad = false;
              });
            }
          } else {
            this.$store.commit("clearManagerToken");
            this.$router.push({ name: "PanelManager-Login" }).then((res) => {
              this.show = true;
              this.showPreLoad = false;
            });
          }
        });
      } else {
        this.$store.commit("clearManagerToken");
        this.$router.push({ name: "PanelManager-Login" }).then((res) => {
          this.show = true;
          this.showPreLoad = false;
        });
      }
    },
  },

  watch: {
    "dataItemEnMineTwo.servicesArray": function (newVal) {
      this.dataItemEnMineTwo.services = newVal.join("-");
    },

    "dataItemEnIndustrialTwo.servicesArray": function (newVal) {
      this.dataItemEnIndustrialTwo.services = newVal.join("-");
    },
    "dataItemEnBuildingTwo.servicesArray": function (newVal) {
      this.dataItemEnBuildingTwo.services = newVal.join("-");
    },

    "userAgentDetails.screenWidth": {
      handler() {
        if (this.userAgentDetails.screenWidth < 1200) {
          this.showMenu = true;
        } else {
          this.showMenu = false;
          this.showSidebar = false;
        }
      },
    },

    $route() {
      if (
        this.$route.meta.requiresAuth &&
        this.$store.state.managerRoutesGroupName
      ) {
        if (!this.$store.state.managerIsAuthenticated) {
          this.$store.commit("clearManagerToken");
          this.$router.push({
            name: `${this.$store.state.managerRoutesGroupName}-Login`,
          });
        }
      }
    },
  },
  computed: {
    filteredSidebarList() {
      // Filter sidebar items based on the user's role
      return this.sidebarListItem.filter((item) =>
        item.rule?.includes(this.userRole)
      );
    },
  },
  async created() {
    await this.checkAreaAccess();
    window.addEventListener("resize", this.HandelGetUserAgentDetails);
    await this.HandelGetUserAgentDetails();
    await this.getUserData(this.$store.state.managerToken);
  },
  destroyed() {
    window.removeEventListener("resize", this.HandelGetUserAgentDetails);
  },

  mounted() {
    // console.log(this.$store.state.managerToken);
    setTimeout(() => {
      this.boxRoForm = false;
    }, 5000);
    this.getDataProvince();
  },
};
</script>

<style scoped>
select,
input,
textarea {
  border: 2px solid #d93444;
  border-radius: 5px;
}

.form-control:focus {
  border-color: none !important;

  box-shadow: 0 0 0 0 !important;
}

/* The Modal (background) */
.display-none-modal {
  display: none !important;
}
.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/**
  * Persian Number: Vazirmatn FD
  * English Number: Vazirmatn
  */
.invalid-feedback {
  display: unset !important;
}

.list-checked-item::before {
  background-image: unset !important;
}

html {
  -moz-transition: left 1s ease 0.1s, width 0s ease-out 0.1s,
    border-width 0s ease-out 0.1s;
  -webkit-transition: left 1s ease 0.1s, width 0s ease-out 0.1s,
    border-width 0s ease-out 0.1s;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.nav-link dropdown-toggle.router-link-exact-active {
  color: red;
}

.nav-link dropdown-toggle.router-link-exact-active:hover {
  color: green;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
td,
tr,
code,
strong,
small,
span,
button,
label {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.select2-container--default {
  display: block;
  width: 100%;
  padding: 0.6125rem 2.25rem 0.6125rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #677788;
  background-color: #fff;

  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 13px 9px;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select2-container--default .select2-selection--single {
  border: unset;
}

.select2-search__field {
  color: #1e2022;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
  border-radius: 0.3125rem;
}

.select2-dropdown select2-dropdown--below {
  background-color: #fff;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-radius: 0.3125rem;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
}

.nav .nav-tabs li {
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slither-enter-active,
.slither-leave-active {
  transition: transform 1s;
}

.slither-enter,
.slither-leave-to {
  transform: translateX(-100%);
}

.slither-enter-to,
.slither-leave {
  transform: translateX(0);
}

.drain-enter-active,
.drain-leave-active {
  transition: transform 1s;
}

.drain-enter,
.drain-leave-to {
  transform: translateY(100%);
}

.drain-enter-to,
.drain-leave {
  transform: translateY(0);
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d1cece;
}
</style>
