export const routes = (parentName) => {
  return [
    {
      path: 'quantum-random-numbers',
      component: () => import('./Index.vue'),
      children: [
        {
          path: '',
          name: `${parentName}-Quantum-Random-Numbers`,
          component: () => import('./List.vue'),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: 'new',
          name: `${parentName}-Quantum-Random-Numbers-New`,
          component: () => import('./Upsert.vue'),
        },
        {
          path: ':id',
          name: parentName + '-' + 'Quantum-Random-Numbers-View',
          component: () => import('./View.vue'),
        },
      ],
    },
  ];
};
