<template>
  <BottomBar />
  <Navbar @city-selected="handleCitySelected" />

  <LoadingArraVue :show="loading" :text="txt" />
  <div><router-view :key="$route.fullPath" :province-id="provinceId" /></div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import BottomBar from "@/components/BottomBar.vue";
import $ from "jquery";
import LoadingArraVue from "@/components/LoadingArra.vue";
export default {
  components: {
    Navbar,
    BottomBar,

    LoadingArraVue,
  },
  data() {
    return {
      loading: true,
      txt: "",
      provinceId: null,
    };
  },

  methods: {
    handleCitySelected(cityId) {
      this.provinceId = cityId;
      // Optionally, you can also handle any other logic when the city changes
    },
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
  },
  mounted() {
    this.sleep(500).then(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped></style>
